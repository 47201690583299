import { useContext } from 'react';

// Context
import { BetSlipContext } from '../../../../../context/BetSlipContext';

export const useCreateSlip = () => {
	const { slipData, setSlipData, highlight, setHighlight, totalOdds, setOdds } =
		useContext(BetSlipContext);

	const createSlip = (
		data: any,
		odd: string | number,
		ticketTitle: string, // title for ticket in bet slip
		marketName: string, // Market name
		className?: string,
		gameId?: number,
		marketId?: number, // Market ID
		eventId?: number, // Event ID
		is_blocked?: number,
		base?: number,
		eventType?: string
	) => {
		const { team1_name, team2_name } = data;

		if (slipData && highlight && totalOdds) {
			// Check for duplicates - remove slip on second click
			let checkDuplicates = false;
			slipData.map((el: any) => {
				if (el.className === className) {
					checkDuplicates = true;
					const keyIndex = highlight.indexOf(className);
					// Remove targeted cell highlight , odds & slip data
					setHighlight([
						...highlight.slice(0, keyIndex),
						...highlight.slice(keyIndex + 1),
					]);

					// Remove odd
					setOdds(totalOdds.filter((q: any) => q !== odd));

					setSlipData(
						slipData.filter((item: any) => item.className !== el.className)
					);
				} else if (el.gameId === gameId) {
					checkDuplicates = true;
				}
				return null;
			});

			if (!checkDuplicates) {
				setHighlight([...highlight, className]);
				setSlipData([
					...slipData,
					{
						odd,
						ticketTitle,
						marketName,
						gameId,
						marketId,
						eventId,
						teams: {
							team1: team1_name,
							team2: team2_name,
						},
						className,
						is_blocked,
						base,
						eventType,
					},
				]);

				setOdds([...totalOdds, Number(odd)]);
			}
		}
	};

	return { createSlip };
};
