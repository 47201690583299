import React, { createContext, useState } from 'react';

// Interfaces
interface ContextProps {
	openNav: boolean;
	setNav: any;
}

interface ProviderProps {
	children: JSX.Element | JSX.Element[] | React.ReactNode;
}

export const NavContext = createContext<Partial<ContextProps>>({});

const NavContextProvider = ({ children }: ProviderProps) => {
	const [openNav, setNav] = useState(false);

	return (
		<NavContext.Provider value={{ openNav, setNav }}>
			{children}
		</NavContext.Provider>
	);
};

export default NavContextProvider;
