import React, { useContext, useEffect } from 'react';

// Context
import { BetSlipContext } from '../../../../context/BetSlipContext';
import { ContractsContext } from '../../../../context/ContractsContext';

// Components
import Button from '../../../../shared/components/Button';

// Style
import { AcceptSlip, BtnSlipWrap } from '../style';

// Services
import { useWeb3Service } from '../../../../shared/services/web3';
import { renderTransactionToast } from '../../../../shared/utils/helpers';

const BtnSlip = () => {
	const {
		slipData,
		setSlipData,
		setTotalOddsHighlight,
		totalOddsHighlight,
		stake,
		setHighlight,
	} = useContext(BetSlipContext);

	const web3Service = useWeb3Service();
	const { address, isConnected, balances, betAllowance, setBetAllowance } = useContext(ContractsContext);

	useEffect(() => {
		web3Service.getAllowanceBettingContract(address)
			.then((allowance: any ) => {
				setBetAllowance(allowance);
			})
			.catch((error: any) => console.log(error));
	})

	const approveMtk = async () => {
		web3Service
			.approveBettingOnMtk(stake)
			.then(async (result: any) => {
				await renderTransactionToast(result);
				setBetAllowance(stake);
			})
			.catch((error: any) => console.log(error));
	};

	const placeBet = async () => {
		web3Service
			.placeBet(slipData, stake)
			.then(async (result: any) => renderTransactionToast(result))
			.catch((error: any) => console.log(error));
	};

	return (
		<BtnSlipWrap>
			{!totalOddsHighlight ? (
				<>
					{isConnected && Number(stake) > 0 ? (
						<Button width='170px' onClick={() => approveMtk()}>
							Approve
						</Button>
					) : (
						<Button width='170px' disabled>
							Approve
						</Button>
					)}
					{betAllowance! &&
					Number(stake) > 0 &&
					Number(stake) <= Number(balances.mtk) &&
					betAllowance > 0 &&
					betAllowance >= Number(stake) ? (
						<Button width='170px' onClick={() => placeBet()} secondary>
							Place Bet
						</Button>
					) : (
						<Button width='170px' disabled secondary>
							Place Bet
						</Button>
					)}
				</>
			) : (
				<AcceptSlip>
					<p>The line, odds or availability of your selection has changed</p>
					<Button
						onClick={() => {
							// Reset slip & total odds highlight
							const slipDataCopy = [...slipData];
							const resetSlip = slipDataCopy
								.map((slip: any) => {
									const item = slip;
									item.highlight = false;
									return item;
								})
								.filter((f) => f.is_blocked === 0);

							// Reset higlihts state
							const newHighlight = resetSlip.map((r: any) => r.className);
							setHighlight(newHighlight);

							setSlipData(resetSlip);
							setTotalOddsHighlight(false);
						}}
					>
						Accept Changes
					</Button>
				</AcceptSlip>
			)}
		</BtnSlipWrap>
	);
};

export default BtnSlip;
