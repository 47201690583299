import React, { createContext, useState } from 'react';

// Interfaces
interface ContextProps {
	// TODO add proper types
	slipData: any;
	setSlipData: any;
	highlight: any[];
	setHighlight: any;
	totalOdds: any;
	setOdds: any;
	totalOddsHighlight: boolean;
	setTotalOddsHighlight: any;
	visible: boolean;
	setVisible: any;
	stake: string;
	handleChange: any;
}

interface ProviderProps {
	children: JSX.Element | JSX.Element[] | React.ReactNode;
}

export const BetSlipContext = createContext<Partial<ContextProps>>({});

const BetSlipContextProvider = ({ children }: ProviderProps) => {
	// Slip data & highlights
	const [slipData, setSlipData] = useState<any>([]);
	const [highlight, setHighlight] = useState<any>([]);

	// Odds data & odds highlights
	const [totalOdds, setOdds] = useState<number[]>([]);
	const [totalOddsHighlight, setTotalOddsHighlight] = useState(false);

	// Minimize slip
	const [visible, setVisible] = useState(false);

	// Stake input
	const [stake, setStake] = useState('');
	const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		setStake(e.target.value);
	};

	return (
		<BetSlipContext.Provider
			value={{
				slipData,
				setSlipData,
				highlight,
				setHighlight,
				totalOdds,
				setOdds,
				totalOddsHighlight,
				setTotalOddsHighlight,
				visible,
				setVisible,
				stake,
				handleChange
			}}
		>
			{children}
		</BetSlipContext.Provider>
	);
};

export default BetSlipContextProvider;
