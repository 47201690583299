import React, { useContext } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { NavContext } from '../context/NavContext';

// Components
import InPlay from '../containers/pages/Football/InPlay';
import PreMatch from '../containers/pages/Football/PreMatch';
import Buy from '../containers/pages/Buy';
import Header from '../containers/general/Header';

const Routes = () => {
	const { openNav, setNav } = useContext(NavContext);

	return (
		<Router>
			{openNav !== undefined && <Header menu={{ openNav, setNav }} />}
			<Switch>
				<Route exact path='/' component={InPlay} />
				<Route exact path='/footbal/pre-match/:id' component={PreMatch} />
				<Route exact path='/buy-mtk' component={Buy} />
			</Switch>
		</Router>
	);
};

export default Routes;
