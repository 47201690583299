import React, { useContext, useEffect, useRef, useState } from 'react';

// Helpers
import {
	useOnClickOutside,
	reduceAddress, renderTransactionToast,
} from '../../../shared/utils/helpers';
import { useMobleDetect } from '../../../shared/utils/hooks/useMobileDetect';

// Components
import Link from '../../../shared/components/Link';
import Logo from '../../../shared/components/Logo';
import Button from '../../../shared/components/Button';

// Icons
import { Wallet, TurnOff } from '../../../shared/components/Icons';
import WrongNetworkLine from '../../../assets/icons/wrongNetworkLine.svg';

// Styles
import { Header, RightWrap, BalanceClaim, HeaderBtns } from '../style';

import { useWeb3Service } from '../../../shared/services/web3';
import { ContractsContext } from '../../../context/ContractsContext';
import ModalC from '../../../shared/components/Modal';

// Interfaces
interface IHeader {
	menu: { openNav: boolean; setNav: any };
}

const Index = ({ menu }: IHeader) => {
	const { isTablet } = useMobleDetect();
	const web3Service = useWeb3Service();
	const {
		address,
		setAddress,
		isConnected,
		setIsConnected,
		network,
		setNetwork,
		balances,
		setBalances,
		networkName,
		setNetworkName,
	} = useContext(ContractsContext);

	// Open Nav
	const { openNav, setNav } = menu;

	// Dots menu
	const parentRef = useRef<HTMLDivElement>(null);
	const childRef = useRef<HTMLDivElement>(null);
	const [openMenu, setOpen] = useState(false);

	useEffect(() => {
		web3Service
			.onAccountChanged()
			.then((addr2: string) => {
				setAddress(addr2);
			})
			.catch((err: any) => console.log(err));

		web3Service
			.onChainChanged()
			.then((chain: string) => {
				web3Service
					.getNetworkName()
					.then((net: any) => setNetworkName(net))
					.catch((err: any) => console.log(err));
				setNetwork(chain);
			})
			.catch((err: any) => console.log(err));
	});

	useEffect(() => {
		if(!address) {
			web3Service
				.isProviderConnected()
				.then((connected: any) => {
					setIsConnected(connected);
				})
				.catch((err: any) => console.log(err));
			if (isConnected) {
				web3Service
					.fetchAddress()
					.then((addr: any) => {
						setAddress(addr);
						web3Service
							.fetchBalances(addr)
							.then((balance: any) => setBalances(balance))
							.catch();
					})
					.catch((err: any) => console.log(err));
				web3Service
					.getNetwork()
					.then((net: any) => setNetwork(net))
					.catch((err: any) => console.log(err));
				web3Service
					.getNetworkName()
					.then((net: any) => setNetworkName(net))
					.catch((err: any) => console.log(err));
			}
		}
	});

	const claimWinnings = async () => {
		const slipId = Number(localStorage.getItem("bettingSlipId"));
		web3Service.claimWinnings(slipId)
			.then((result: any) => renderTransactionToast(result))
			.catch((error: any) => console.log(error));
	}

	const disconnect = () => {
		web3Service.disconnectProvider();
	}

	const modalBody = (
		<div className="modal-body">
			<p className="modal-title">Wrong Network</p>
			<p>Please connect to Binance Smart Chain Testnet</p>
		</div>
	)

	useOnClickOutside(parentRef, childRef, openMenu, () => setOpen(false));

	return (
		<Header>
			{!isTablet ? (
				<Link to='/' className='logo'>
					<Logo />
				</Link>
			) : (
				<div className='m-wrap'>
					<button
						className='burger'
						type='button'
						onClick={() => setNav(!openNav)}
					>
						<span />
						<span />
						<span />
					</button>
					{document.title !== 'Home' && <p>{document.title}</p>}
				</div>
			)}
			<RightWrap>
				<BalanceClaim>
					<Button onClick={() => {claimWinnings()}}>Claim Winnings</Button>
					<p>Balance: {(balances && balances.mtk) || '-'}</p>
				</BalanceClaim>
				<HeaderBtns>
					{network && network && (
						<Button className='networkButton' backgroundColor='#374156' disabled>
							{networkName}
						</Button>
					)}
					{!isConnected ? (
						<Button onClick={() => web3Service.connectToProvider()}>
							<Wallet /> Connect Wallet
						</Button>
					) : network !== 'Wrong network' && (
						<Button onClick={() => web3Service.disconnectProvider()}>
							{reduceAddress(address, 4, 6)} <TurnOff />
						</Button>
					)}
					{network === 'Wrong network' &&
						<Button className='wrongNetworkButton' onClick={() => disconnect()}>
							<div>
								<img src={WrongNetworkLine} alt="~"/>
								<span>Wrong Network</span>
							</div>
						</Button>
					}
				</HeaderBtns>
			</RightWrap>
			{network === 'Wrong network' && <ModalC modalBody={modalBody}/>}
		</Header>
	);
};
Index.whyDidYouRender = true;

export default Index;
