import React, { useState, useEffect } from 'react';

const GoalToast = ({ scoreRef }: any) => {
	const [show, setShow] = useState(true);

	// Clear goal toast after 5 sec and update ref
	useEffect(() => {
		const timer = setTimeout(() => {
			setShow(false);
			// eslint-disable-next-line no-param-reassign
			scoreRef.current = false;
		}, 5000);

		return () => {
			clearTimeout(timer);
		};
	}, [scoreRef]);

	return (
		<>
			{show && (
				<p
					style={{
						background: 'yellow',
						color: 'black',
						padding: '1px 5px',
						fontSize: '12px',
						marginLeft: '10px',
					}}
				>
					GOAL
				</p>
			)}
		</>
	);
};

export default GoalToast;
