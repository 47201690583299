export const updateStats = (gameForUpdateCopy: any, newGame: any) => {
	const { stats } = gameForUpdateCopy;
	const incommingStats = newGame.stats;

	if (incommingStats) {
		Object.keys(incommingStats).forEach((k) => {
			if (k in stats) {
				if (incommingStats[k]) {
					Object.keys(incommingStats[k]).forEach((g) => {
						if (g in stats[k]) {
							stats[k][g] = incommingStats[k][g];
						}
					});
				}
			} else {
				stats[k] = incommingStats[k];
			}
		});
	}

	return stats;
};
