import styled from 'styled-components';

export const ModalWrap = styled.div`
	width: 100%;
	position: relative;
	display: flex;
	flex-direction: column;
	align-items: center;

	.modal-title {
    font-family: Roboto,serif;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 21px;
		margin-bottom: 20px;
	}
	
	.modal-body {
		padding: 30px;
	}
	
	.top-close {
		padding-top: 12px;
		padding-right: 17px;
		position: absolute;
		right: 0;
		top: 0;
	}
`;
