import React, { useContext } from 'react';

// Context
import { ApiContext } from '../../../context/ApiContext';
import { DropdownContext } from '../../../context/DropdownContext';

// Components
import Link from '../../../shared/components/Link';

// Icons
import { DropIcon } from '../../../shared/components/Icons';

// Styles
import { NWrap, NContainer, NItems, NSingleItem, NSubItem } from '../style';

const Nav = () => {
	const { drop, setDrop } = useContext(DropdownContext);
	const { preMatchGames } = useContext(ApiContext);

	// console.log(upcomingRegions);

	return (
		<NWrap>
			<NContainer>
				<NItems>
					<NSingleItem>
						<Link to='/buy-mtk' style={{ width: '100%', opacity: '1' }}>
							Buy MTK
						</Link>
					</NSingleItem>
					<NSingleItem>
						<Link to='/' style={{ width: '100%', opacity: '1' }}>
							Football - In-Play
						</Link>
					</NSingleItem>
					<NSingleItem>
						<div className={`${drop} title`}>
							<p aria-hidden='true' onClick={() => setDrop(!drop)}>
								Football - Pre-Match
								<DropIcon />
							</p>
						</div>
						<NSubItem className={drop ? 'show' : 'hide'}>
							{preMatchGames &&
								preMatchGames.map((region: any, index: number) => (
									<Link
										to={`/footbal/pre-match/${region.id} `}
										style={{ width: '100%', opacity: '1' }}
										key={index}
										state={{
											ids: region.competition,
										}}
									>
										{region.alias}
									</Link>
								))}
						</NSubItem>
					</NSingleItem>
				</NItems>
			</NContainer>
		</NWrap>
	);
};
export default Nav;
