import React, { useContext } from 'react';

// Context
import { BetSlipContext } from '../../../../context/BetSlipContext';

// Helpers & Hooks
import { useHighlightCell } from '../helpers/hooks/useHighlightCell';
import { useRenderSlip } from '../helpers/hooks/useRenderSlip';

// Style
import { MiddleSlipWrap } from '../style';

const MiddleSlip = () => {
	const {
		slipData,
		setSlipData,
		totalOdds,
		setOdds,
		totalOddsHighlight,
		setTotalOddsHighlight,
		stake,
		handleChange,
	} = useContext(BetSlipContext);

	const { setCellHighlight } = useHighlightCell();
	const { renderSlip } = useRenderSlip();

	return (
		<>
			{slipData && totalOdds && (
				<MiddleSlipWrap>
					{slipData.length > 1 && (
						<button
							type='button'
							className='remove'
							onClick={() => {
								// Remove all highlights , odds & slip data
								setOdds([]);
								setSlipData([]);
								setCellHighlight([]);
								setTotalOddsHighlight(false);
							}}
						>
							Remove all
						</button>
					)}
					<div
						className={slipData.length > 3 ? 'info-wrap overflow' : 'info-wrap'}
					>
						{renderSlip}
					</div>
					{slipData.length > 1 && (
						<div className='total'>
							<p>Total odds</p>
							<p className={totalOddsHighlight ? 'highlight' : ''}>
								{parseFloat(
									// Filter out strings '-' & calculate multiplication
									totalOdds
										.filter((q: any) => q !== '-')
										.reduce((partialSum: any, q: any) => partialSum * q, 1)
										.toFixed(3)
								)}
							</p>
						</div>
					)}
					{!totalOddsHighlight && (
						<div className='input-wrap'>
							<input
								type='text'
								placeholder='Set stake'
								onChange={handleChange}
								value={stake}
							/>
							<p className='label'>MTK</p>
						</div>
					)}
				</MiddleSlipWrap>
			)}
		</>
	);
};

export default MiddleSlip;
