import styled from 'styled-components';
import { Col } from '../../../shared/styles/GeneralStyles';
import { font } from '../../../shared/styles/variables';

export const VCol = styled(Col)`
	padding: 24px;
	display: flex;
	flex-direction: column;
	background-color: var(--white);
	box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.04);
	border-radius: 10px;
`;

export const DHeader = styled.div`
	display: flex;
	padding: 12px;
	justify-content: space-between;
	border-radius: 4px;

	&:nth-of-type(2) {
		background-color: var(--blue2);
		margin-top: 8px;
		margin-bottom: 12px;
		border-radius: 12px;
	}

	.row {
		padding: 0;
		display: flex;
		justify-content: center;
		align-items: center;
		font-size: 14px;
		line-height: 16px;
		width: 84px;
		color: var(--shadowBlue);

		p {
			font: inherit;
			color: inherit;
		}

		&:nth-of-type(1) {
			width: 100%;
			flex: 1;
			justify-content: flex-start;

			p {
				font-weight: 500;
				color: var(--text);
			}
		}

		&:nth-of-type(4),
		&:nth-of-type(7) {
			margin-right: 28px;
		}
	}

	&:first-of-type {
		display: none;
		padding-bottom: 0;

		.row {
			width: 245px;
			margin-right: 37px;
			font-size: 16px;

			&:last-of-type {
				margin-right: 0;
			}
		}
	}
`;

export const TContainer = styled.div`
	width: 100%;

	h1 {
		font-size: 24px;
		line-height: 28px;
	}

	.ng {
		width: 100%;
		height: 100%;
		align-items: flex-end;
		display: flex;
		align-items: flex-end;
		justify-content: center;
	}

	.table {
		margin-bottom: 30px;

		&:first-of-type {
			${DHeader} {
				&:first-of-type {
					display: flex;
				}
			}
		}

		&.prematch .score {
			display: none !important;
		}
	}
`;

export const TableWrap = styled.div`
	.row {
		display: flex;
		align-items: center;
		background-color: var(--sidebarBg);
		margin-bottom: 12px;
		box-sizing: border-box;
		border-radius: 12px;
		padding: 12px;

		&.row-false {
			display: none;
		}

		.cell {
			padding: 0;
			display: flex;
			background: #161b25;
			box-sizing: border-box;
			border-radius: 12px;
			justify-content: center;
			margin-right: 6px;
			width: 80px;
			font-size: 14px;
			line-height: 16px;
			overflow: hidden;

			&:nth-of-type(1) {
				width: 100%;
				flex: 1;
				margin-right: 20px;
				border: 0;
				border-radius: 0;
				justify-content: space-between;

				p {
					font-size: 14px;
					line-height: 16px;
					padding: 1px 0;
				}

				div {
					margin-bottom: 3px;

					&.score {
						margin-left: 10px;
						display: flex;

						p {
							text-align: center;
							width: 30px;
							margin-bottom: 3px;

							&:last-of-type {
								margin-top: 3px;
								font-size: 12px;
								color: var(--gray);
							}
						}
					}

					div {
						display: flex;
						justify-content: space-between;
						flex-direction: column;
					}
				}

				.live {
					display: inline-block;
					margin-top: 3px;
					font-size: 12px;
					color: var(--red);
					${font.medium};
					text-transform: uppercase;
					font-style: italic;

					span {
						color: var(--shadowBlue);
						font-style: normal;
						text-transform: initial;
						display: inline-block;
					}
				}

				.upcoming {
					color: var(--shadowBlue);
					font-size: 12px;
				}
			}

			&:nth-of-type(4),
			&:nth-of-type(7) {
				margin-right: 30px;
			}

			&:last-of-type {
				margin-right: 0;
			}

			button {
				padding: 18px;
				width: 100%;
				border-radius: 12px;
				border: 1px solid rgba(126, 150, 184, 0.3);

				&.active {
					background-color: var(--activeGame);
				}

				&.inactive {
					pointer-events: none;
					cursor: unset;
					border: 0;
				}

				&.disabled {
					opacity: 0.3;
				}
			}
		}
	}
`;

// Bet Slip
export const SlipWrap = styled.div`
	display: block;
	position: fixed;
	width: 380px;
	background-color: var(--modalBg);
	bottom: 30px;
	left: 57%;
	transform: translateX(-50%);
	padding: 16px 0;
	border-radius: 12px;

	p {
		margin-bottom: 0;
	}

	.remove {
		color: var(--shadowBlue);
		margin-top: 8px;
		margin-bottom: 16px;
	}

	.total {
		display: flex;
		justify-content: space-between;
		margin: 16px 0;
		padding-right: 12px;

		p:last-of-type {
			width: auto;
			text-align: center;
			padding: 2px 6px;
			border-radius: 6px;

			&.highlight {
				background-color: #7e96b8;
				color: #f9f9f9;
			}
		}
	}
`;

export const TopSlipWrap = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-bottom: 8px;
	padding: 0 12px;
	pointer-events: none;

	.title {
		display: flex;
		align-items: center;

		span {
			display: flex;
			align-items: center;
			justify-content: center;
			background-color: var(--blue);
			color: var(--white);
			width: 32px;
			height: 32px;
			border-radius: 100%;
			margin-right: 12px;
			font-size: 18px;
			line-height: 21px;
		}
	}

	svg {
		padding: 10px;
		box-sizing: content-box;
		cursor: pointer;
		pointer-events: auto;
	}

	&.reverse {
		margin-bottom: 0;
		pointer-events: auto;
		cursor: pointer;

		svg {
			transform: rotate(180deg);
		}
	}
`;

export const MiddleSlipWrap = styled.div`
	display: block;
	padding-left: 12px;

	.info-wrap {
		padding-right: 12px;

		&.overflow {
			overflow-y: scroll;
			max-height: 400px;
		}

		.info {
			display: flex;
			flex-wrap: wrap;
			align-items: center;
			padding: 12px 0;

			.status {
				display: flex;
				align-items: center;
				width: 85%;

				svg {
					margin-bottom: 4px;
					cursor: pointer;
					padding: 10px;
					box-sizing: content-box;
					margin-left: -10px;
				}

				p {
					font-size: 18px;
					line-height: 22px;
				}
			}

			.odd {
				width: 15%;
				text-align: center;
				font-size: 18px;
				line-height: 22px;
				padding: 2px 6px;
				border-radius: 6px;
			}

			.odd-active {
				background-color: #7e96b8;
				color: #f9f9f9;
			}

			p {
				width: 100%;
				font-size: 12px;
				line-height: 14px;
				margin-bottom: 4px;
			}

			&.multiple {
				border-top: 1px solid var(--shadowBlue);

				&:last-of-type {
					border-bottom: 1px solid var(--shadowBlue);
				}
			}

			&.blocked {
				opacity: 0.5;
			}
		}
	}

	.input-wrap {
		position: relative;
		margin-bottom: 16px;
		margin-right: 12px;

		input {
			width: 356px;
			height: 38px;
			background-color: var(--background);
			box-shadow: none;
			outline: none;
			border: 0;
			border-radius: 12px;
			padding: 12px;
			color: var(--text);

			&::placeholder {
				font-size: 12px;
				color: var(--text);
				opacity: 0.6;
			}
		}

		.label {
			position: absolute;
			top: 50%;
			transform: translateY(-50%);
			right: 16px;
			font-size: 12px;
			margin-bottom: 0;
		}
	}
`;

export const BtnSlipWrap = styled.div`
	display: flex;
	padding: 0 12px;

	button {
		width: 170px;

		&:first-of-type {
			margin-right: 16px;
		}
	}
`;

export const AcceptSlip = styled.div`
	p {
		font-size: 14px;
		line-height: 16px;
		text-align: center;
		margin-bottom: 19px;
	}

	button {
		width: 100%;
	}
`;
