import * as React from 'react';

function SvgRemoveIcon(props: React.SVGProps<SVGSVGElement>) {
	return (
		<svg
			width={10}
			height={10}
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
			{...props}
		>
			<path
				d='M10 1.007L8.993 0 5 3.993 1.007 0 0 1.007 3.993 5 0 8.993 1.007 10 5 6.007 8.993 10 10 8.993 6.007 5 10 1.007z'
				fill='#F9F9F9'
			/>
		</svg>
	);
}

export default SvgRemoveIcon;
