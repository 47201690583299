import { useEffect } from 'react';

// Toastify notifications
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// Click outside handler
export function useOnClickOutside(
	parentRef: any,
	childRef: any,
	openMenu: boolean,
	handler: any
) {
	useEffect(() => {
		const listener = (event: TouchEvent | MouseEvent) => {
			if (!openMenu) {
				return;
			}

			// Do nothing if clicking ref's element or descendent elements
			if (
				!parentRef.current ||
				parentRef.current.contains(event.target) ||
				!childRef.current ||
				childRef.current.contains(event.target)
			) {
				return;
			}

			handler(event);
		};
		document.addEventListener('mousedown', listener);
		document.addEventListener('touchstart', listener);
		return () => {
			document.removeEventListener('mousedown', listener);
			document.removeEventListener('touchstart', listener);
		};
	}, [parentRef, childRef, openMenu, handler]);
}

export const reduceAddress = (address: string, lengthLeft: number, lengthRight: number) => {
	const addressLeftPart = address.substring(0, lengthLeft);
	const addressRightPart = address.substring(42 - lengthRight, 42);
	return `${addressLeftPart}...${addressRightPart}`;
};

export const renderTransactionToast = async (waitForTransaction: any) => {
	if (waitForTransaction.hash) {
		toast.info('Transaction submitted to the Blockchain!');
		const result = await waitForTransaction.wait();

		if (result.status === 0) {
			toast.error('Transaction Failed!');
		}
		if (result.status === 1) {
			toast.success(`Transaction Successful!`);
		}
	}
}

export const validateNumberInput = (e: any) => {
	// let val = e.target.value;
	// val = val.replace(/([^0-9.]+)/, "");
	// const match = /(\d{0,7})[^.]*((?:\.\d{0,3})?)/g.exec(val);
	// const value = match![1] + match![2];
	// e.target.value = value;
	// return value;
	let stringValue = e.target.value.toString()
	if (stringValue.indexOf(",")) {
		stringValue = stringValue.replace(",", ".")
	}
	e.target.value = stringValue
		.split(".")
		.map((el: string,i: number)=> i
			? el.split("").slice(0,3).join("")
			:el
		)
		.join(".");
	return e.target.value
};
