import React, { useContext } from 'react';

// Context
import { ApiContext } from '../../../../../context/ApiContext';
import { BetSlipContext } from '../../../../../context/BetSlipContext';

// Helpers & Hooks
import { getNewMarketPrice } from '../getNewMarketPrice';
import { useHighlightCell } from './useHighlightCell';

// Components
import { RemoveIcon } from '../../../../../shared/components/Icons';

// Interfaces
interface ISlip {
	className: string;
	odd: number;
	marketName: string;
	teams: {
		team1: string;
		team2: string;
	};
	ticketTitle: string;
	length: number;
	gameId?: number;
	marketId?: number;
	eventId?: number;
	is_blocked?: number;
	highlight?: boolean;
}

export const useRenderSlip = () => {
	const { games, prevGames } = useContext(ApiContext);
	const { slipData, setSlipData, totalOdds, setOdds, setTotalOddsHighlight } =
		useContext(BetSlipContext);
	const { setCellHighlight, highlight } = useHighlightCell();

	// Remove slip entry
	const slipRemove = (arr: Array<ISlip>, value: number) =>
		arr.filter((el: ISlip, index: number) => index !== value);

	const bla = React.useMemo(() => {
		const rSlip =
			slipData &&
			totalOdds &&
			slipData.length !== 0 &&
			slipData
				.map((slip: ISlip, index: number) => {
					const {
						ticketTitle,
						marketName,
						teams: { team1, team2 },
						className,
						odd,
					} = slip;

					const keyIndex = highlight?.indexOf(className) as any;

					// Get new odd price
					const [newOddPrice, blocked] = getNewMarketPrice(
						games,
						prevGames,
						slip
					);

					if (newOddPrice !== odd) {
						// Update slip data(odds)
						const updateSlip = [...slipData];
						updateSlip[index] = {
							...updateSlip[index],
							odd: newOddPrice,
							highlight: true,
							is_blocked: newOddPrice === '-' ? 1 : 0,
						};
						setSlipData(updateSlip);

						// Update total odds
						const updateOdds = [...totalOdds];
						totalOdds[index] = newOddPrice;
						setOdds(updateOdds);
						setTotalOddsHighlight(true);
					}

					return (
						<React.Fragment key={index}>
							<div
								className={`${slipData.length > 1 ? 'info multiple' : 'info'} ${
									blocked === 1 ? 'blocked' : ''
								}`}
							>
								<div className='status'>
									<RemoveIcon
										onClick={() => {
											// Remove targeted cell highlight , odds & slip data

											if (highlight) {
												setCellHighlight([
													...highlight.slice(0, keyIndex),
													...highlight.slice(keyIndex + 1),
												]);
											}

											// Remove odd & remove all odds if empty arr
											if (slipData.length > 1) {
												setOdds(totalOdds.filter((q: any) => q !== odd));
											} else {
												setOdds([]);
												setTotalOddsHighlight(false);
											}

											setSlipData(slipRemove(slipData, index));
										}}
									/>
									<p>{ticketTitle}</p>
								</div>
								<p
									className={`odd ${
										slipData[index].highlight ? 'odd-active' : ''
									}`}
								>
									{odd}
								</p>
								<p>{marketName}</p>
								<p>
									{team1} vs {team2}
								</p>
							</div>
						</React.Fragment>
					);
				})
				.reverse();

		return rSlip;
		// 'games', 'highlight', 'setCellHighlight', 'setOdds', 'setTotalOddsHighlight', and 'totalOdds'
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [slipData, setSlipData, games]);

	return { renderSlip: bla };
};
