import { useContext } from 'react';

// Context
import { BetSlipContext } from '../../../../../context/BetSlipContext';

// Highlight cell onClick
export const useHighlightCell = () => {
	const { highlight, setHighlight } = useContext(BetSlipContext);

	const cellHighlight = (className: string) => {
		if (highlight) {
			setHighlight([...highlight, className]);
		}
	};

	const setCellHighlight = (params: any) => {
		setHighlight(params);
	};

	return { cellHighlight, highlight, setCellHighlight };
};
