import React, { useContext } from 'react';

// Context
import { BetSlipContext } from '../../../../context/BetSlipContext';

// Components
import { DropIcon } from '../../../../shared/components/Icons';

// Style
import { TopSlipWrap } from '../style';

const TopSlip = () => {
	const { slipData, visible, setVisible } = useContext(BetSlipContext);

	return (
		<>
			{slipData && (
				<TopSlipWrap
					className={visible ? 'reverse' : ''}
					onClick={() => setVisible(!visible)}
				>
					<div className='title'>
						<span>{slipData.length}</span>
						<p>Bet slip</p>
					</div>
					<DropIcon onClick={() => setVisible(!visible)} />
				</TopSlipWrap>
			)}
		</>
	);
};

export default TopSlip;
