import React, { useContext, useEffect, useState } from 'react';
import { toast } from 'react-toastify';


// Components
import Button from '../../../shared/components/Button';
import Link from '../../../shared/components/Link';
import { useWeb3Service } from '../../../shared/services/web3';

// Styles
import { Wrap, Card, CardItem } from './style';
import { ContractsContext } from '../../../context/ContractsContext';

import { renderTransactionToast, validateNumberInput } from "../../../shared/utils/helpers";

const Buy = (props: any) => {
	const web3Service = useWeb3Service();
	const { isConnected, balances, allowance, setAllowance, address, setBalances } = useContext(ContractsContext);
	const [amount, setAmount] = useState('');

	useEffect(() => {
		web3Service.getAllowanceBusd(address)
			.then((allowanceBusd: any) => {
				setAllowance(Number(allowanceBusd).toFixed(3))
			})
			.catch((error: any) => console.log(error));
	})
	web3Service.onAccountChanged()
	.then((addr2: string) => {
		console.log(`Buy: ${addr2}`);
	})
	.catch((err: any) => console.log(err));

	const approve = async () => {
		const approveAmount = Number(amount);
		if (approveAmount > 0) {
			web3Service.approveBusd(approveAmount)
				.then(async (waitForTransaction: any) => {
					await renderTransactionToast(waitForTransaction)
				})
				.catch((error: any) => console.log(error));
		} else {
			toast.error('Amount has to be greater than 0!');
		}
	};

	const buyStablecoin = async () => {
		web3Service.buyMtkToken(Number(amount))
			.then(async (waitForTransaction: any) => {
				await renderTransactionToast(waitForTransaction)
				web3Service
					.fetchBalances(address)
					.then((balance: any) => setBalances(balance))
					.catch();
			})
			.catch((error: any) => console.log(error))
	};

	const sellStablecoin = async () => {
		web3Service.sellMtkToken(Number(amount))
			.then(async (waitForTransaction: any) => {
				await renderTransactionToast(waitForTransaction)
			})
			.catch((error: any) => console.log(error))
	};

	const getBNBFunds = async () => {
		web3Service.getBNBFromFaucet(address)
			.then(async (waitForTransaction: any) => {
				if (!waitForTransaction) {
					return toast.error('Not enough balance on faucet!')
				}
				return renderTransactionToast(waitForTransaction)
			})
			.catch((error: any) => console.log(error))
	}

	return (
		<Wrap>
			<Card>
				<p className='title'>Balances</p>
				<CardItem>
					<p>BNB Balance</p>
					<p>{balances && balances.bnb} BNB</p>
				</CardItem>
				<CardItem>
					<p>BUSD Balance</p>
					<p>{balances && balances.busd} BUSD</p>
				</CardItem>
				<CardItem>
					<p>Stablecoin Balance</p>
					<p>{balances && balances.mtk} MTK</p>
				</CardItem>
			</Card>

			<Card>
				<p className='title'>Allowance</p>
				<CardItem>
					<p>Stablecoin allowance to spend BUSD</p>
					<p>{allowance} BUSD</p>
				</CardItem>
			</Card>

			<Card>
				<p className='title'>Faucets</p>
				<CardItem>
					<p>BUSD Faucet</p>
					<Link to='https://testnet.binance.org/faucet-smart' external>
						https://testnet.binance.org/faucet-smart
					</Link>
				</CardItem>
				<CardItem>
					<p>BNB Faucet</p>
					<Button className="faucetButton" onClick={() => getBNBFunds()}>Get { process.env.REACT_APP_FAUCET_GET_AMOUNT || 0 } BNB</Button>
				</CardItem>
			</Card>

			<Card>
				{props.active === 'buy' ?
					<p className='title'>Buy our token</p> :
					<p className='title'>Sell our token</p>
				}
				<input
					type='text'
					value={amount}
					onChange={(e: any) => setAmount(validateNumberInput(e))}
					placeholder='Enter Stablecoin or BUSD amount'
				/>
				{props.active === 'buy' ?
					<CardItem>
						{ isConnected ?
							<Button width='170px' onClick={() => approve()}>Approve</Button> :
							<Button width='170px' disabled>Approve</Button>
						}
						{(Number(amount) > 0 && Number(amount) <= Number(balances.busd) && allowance > 0 && allowance >= Number(amount)) ?
							<Button width='170px' onClick={() => buyStablecoin()} secondary>
								Buy
							</Button> :
							<Button width='170px' disabled secondary>
								Buy
							</Button>
						}
					</CardItem> :
					<CardItem>
						{(Number(amount) > 0 && Number(amount) <= balances.mtk) ?
							<Button width='170px' onClick={() => sellStablecoin()} secondary>
								Sell
							</Button> :
							<Button width='170px' disabled secondary>
								Sell
							</Button>
						}
					</CardItem>
				}
			</Card>
		</Wrap>
	);
};

export default Buy;
