import React, { createContext, useState, useEffect, useRef } from 'react';
import useWebSocket from 'react-use-websocket';

// Api queries
import { wsAuth, getInPlay, getUpcomingRegions } from '../config/api';

// Update Helpers
import { updateInfo } from './helpers/info';
import { updateMarkets } from './helpers/markets';
import { updateStats } from './helpers/stats';
import { sendFinishedGameData } from '../shared/services/api';

// Interfaces
interface ContextProps {
	games: any;
	prevGames: any;
	setGameInfo: any;
	preMatchGames: any;
}

interface ProviderProps {
	children: JSX.Element | JSX.Element[] | React.ReactNode;
}

export const ApiContext = createContext<Partial<ContextProps>>({});

const ApiContextProvider = ({ children }: ProviderProps) => {
	const [games, setGameInfo] = useState<any>();

	// Get previous games state
	const prevGamesRef = useRef();
	useEffect(() => {
		prevGamesRef.current = games;
	});
	const prevGames = prevGamesRef.current;

	const [preMatchGames, setUpRegions] = useState<any>();

	const { sendJsonMessage, readyState } = useWebSocket(
		`${process.env.REACT_APP_WS_SERVER}`,
		{
			onMessage: (evt: MessageEvent<any>) => {
				const result = JSON.parse(evt.data);
				if (result?.rid === 1) {
					// ADD New Games
					const firstData = result?.data?.data?.region;

					setGameInfo(firstData);
				} else if (result?.rid === '0') {
					// UPDATE existing games
					const uppcomingData = Object.values(result.data)[0] as any;
					const newRegions = uppcomingData.region;
					let newIncReg = [] as any;

					const dataForUpdate = games.flatMap((regionForUpdate: any) => {
						let regionForUpdateCopy = {
							...regionForUpdate,
						};

						newRegions.map((newRegion: any) => {
							if (regionForUpdate.id === newRegion.id) {
								// Delete region
								if (newRegion.deleted) {
									regionForUpdateCopy = null;
								} else {
									// Competitions
									let newIncComp = [] as any;
									const compForUpdate = regionForUpdate.competition.flatMap(
										(competitionForUpdate: any) => {
											let competitionForUpdateCopy = {
												...competitionForUpdate,
											};

											newRegion.competition.map((newCompetition: any) => {
												if (competitionForUpdate.id === newCompetition.id) {
													// Delete competition
													if (newCompetition.deleted) {
														competitionForUpdateCopy = null;
													} else {
														// Games
														let newIncGame = [] as any;
														const gamesForUpdate =
															competitionForUpdate.game.flatMap(
																(gameForUpdate: any) => {
																	let gameForUpdateCopy = {
																		...gameForUpdate,
																	};

																	// Normal competition
																	newCompetition.game.map(
																		async (newGame: any) => {
																			// console.log(newGame);

																			if (gameForUpdate.id === newGame.id) {
																				gameForUpdateCopy = {
																					...gameForUpdate,
																				};

																				// Market
																				if (newGame.market) {
																					gameForUpdateCopy.market =
																						updateMarkets(
																							gameForUpdateCopy,
																							newGame
																						);
																				}

																				// Stats
																				if (newGame.stats) {
																					gameForUpdateCopy.stats = updateStats(
																						gameForUpdateCopy,
																						newGame
																					);
																				}

																				// Info
																				if (newGame.info) {
																					if (
																						newGame.info.current_game_state ===
																						'finished'
																					) {
																						await sendFinishedGameData(
																							newGame.id,
																							newGame.text_info
																						);
																					}

																					gameForUpdateCopy.info = updateInfo(
																						gameForUpdateCopy,
																						newGame
																					);
																				}

																				// Text Info
																				if (newGame.text_info) {
																					const incommingText =
																						newGame.text_info;
																					gameForUpdateCopy.text_info =
																						incommingText;
																				}

																				// Block Game
																				if (
																					newGame.is_blocked === 0 ||
																					newGame.is_blocked === 1
																				) {
																					const incommingBlock =
																						newGame.is_blocked;
																					gameForUpdateCopy.is_blocked =
																						incommingBlock;
																				}

																				// Delete game
																				if (newGame.deleted) {
																					if (
																						gameForUpdate.info
																							.current_game_state !== 'finished'
																					) {
																						await sendFinishedGameData(
																							newGame.id,
																							gameForUpdate.text_info
																						);
																					}

																					gameForUpdateCopy = null;
																				}
																			} else if (newGame.team1_name) {
																				// New game inc
																				newIncGame = [...newIncGame, newGame];
																			}
																			return null;
																		}
																	);

																	return gameForUpdateCopy !== null
																		? gameForUpdateCopy
																		: [];
																}
															);

														// Remove all duplicates from incoming new games arr
														const newGamesPurged = newIncGame.filter(
															(v: any, i: any, a: any) =>
																a.findIndex((t: any) => t.id === v.id) === i
														);

														competitionForUpdateCopy.game = [
															...gamesForUpdate,
															...newGamesPurged,
														];
													}
												} else if (newCompetition.name) {
													// New competition inc
													newIncComp = [...newIncComp, newCompetition];
												}

												return null;
											});

											return competitionForUpdateCopy !== null
												? competitionForUpdateCopy
												: [];
										}
									);

									// Remove all duplicates from incoming new comps arr
									const newCompsPurged = newIncComp.filter(
										(v: any, i: any, a: any) =>
											a.findIndex((t: any) => t.id === v.id) === i
									);

									regionForUpdateCopy.competition = [
										...compForUpdate,
										...newCompsPurged,
									];
								}
							} else if (newRegion.name) {
								// New region inc
								newIncReg = [...newIncReg, newRegion];
							}

							return null;
						});

						return regionForUpdateCopy !== null ? regionForUpdateCopy : [];
					});

					// Remove all duplicates from incoming new region arr
					const newRegionPurged = newIncReg.filter(
						(v: any, i: any, a: any) =>
							a.findIndex((t: any) => t.id === v.id) === i
					);

					setGameInfo([...dataForUpdate, ...newRegionPurged]);
				} else if (result?.rid === 2) {
					const prematchData = result?.data?.data?.region;
					setUpRegions(prematchData);
				}
			},
			shouldReconnect: () => true,
		}
	);

	useEffect(() => {
		sendJsonMessage(wsAuth);
		sendJsonMessage(getInPlay);
		sendJsonMessage(getUpcomingRegions);
	}, [sendJsonMessage, readyState]);

	return (
		<ApiContext.Provider
			value={{ games, prevGames, setGameInfo, preMatchGames }}
		>
			{children}
		</ApiContext.Provider>
	);
};

export default ApiContextProvider;
