import React from 'react';

// Styles
import { Wrap, WrapSecond } from './style';

// Interfaces
export interface TBtn {
	onClick?: any;
	children: JSX.Element | JSX.Element[] | React.ReactNode;
	className?: string;
	secondary?: boolean;
	width?: string;
	disabled?: boolean;
	backgroundColor?: string; 
}

const Button = ({
	onClick,
	children,
	className,
	secondary,
	width,
	disabled,
	backgroundColor
}: TBtn) =>
	!secondary ? (
		<Wrap
			onClick={onClick}
			style={{
				width: width && width,
				backgroundColor
			}}
			className={className}
			disabled={disabled}
		>
			{children}
		</Wrap>
	) : (
		<WrapSecond
			onClick={onClick}
			style={{
				width: width && width,
			}}
			className={className}
			disabled={disabled}
		>
			{children}
		</WrapSecond>
	);

export default Button;
