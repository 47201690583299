import React, { createContext, useState } from 'react';

// Interfaces
interface ContextProps {
	drop: boolean;
	setDrop: any;
}

interface ProviderProps {
	children: JSX.Element | JSX.Element[] | React.ReactNode;
}

export const DropdownContext = createContext<Partial<ContextProps>>({});

const DropdownContextProvider = ({ children }: ProviderProps) => {
	const [drop, setDrop] = useState(false);

	return (
		<DropdownContext.Provider value={{ drop, setDrop }}>
			{children}
		</DropdownContext.Provider>
	);
};

export default DropdownContextProvider;
