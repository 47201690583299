export const color = {
	text: '#F9F9F9',
	background: '#070A10',
	modalBg: '#283040',
	sidebarBg: '#161B25',
	white: '#FFF',
	primary: '#2B62BD',
	primaryHover: '#5581CA',
	primaryPush: '#234F98',
	secondary: '#289961',
	secondaryHover: '#53AD81',
	secondaryPush: '#289961',
	activeGame: '#2D374A',
	shadowBlue: '#7E96B8',
	red: '#FF1400',
	blue: '#365486',
	blue2: '#1C263C',
	gray: '#C1C1C1',
};

export const font = {
	regular: 'font-family: "Roboto", sans-serif; font-weight: normal;',
	medium: 'font-family: "Roboto", sans-serif; font-weight: 500;',
};

// Media queries
const size = {
	mobileS: '340px',
	mobileM: '375px',
	mobileL: '525px',
	tabletS: '655px',
	tablet: '768px',
	tabletL: '1200px',
	laptopL: '1440px',
	desktop: '2560px',
};

export const device = {
	mobileS: `(max-width: ${size.mobileS})`,
	mobileM: `(max-width: ${size.mobileM})`,
	mobileL: `(max-width: ${size.mobileL})`,
	tabletS: `(max-width: ${size.tabletS})`,
	tablet: `(max-width: ${size.tablet})`,
	tabletL: `(max-width: ${size.tabletL})`,
	laptopL: `(max-width: ${size.laptopL})`,
	desktop: `(max-width: ${size.desktop})`,
	desktopL: `(max-width: ${size.desktop})`,
};
