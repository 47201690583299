import React, { useContext } from 'react';
import { useLocation } from 'react-router-dom';

// Context
import { NavContext } from '../../context/NavContext';

// Components
import Nav from './Nav';

// Styles
import { Container, Row } from '../../shared/styles/GeneralStyles';

// TS
interface ILayout {
	children: JSX.Element | JSX.Element[] | React.ReactNode;
}

const Layout = ({ children }: ILayout) => {
	const { openNav, setNav } = useContext(NavContext);
	const location = useLocation();

	return (
		<>
			{/* <Header menu={{ openNav, setNav }} /> */}
			<main className={location.pathname.replaceAll('/', '')}>
				<aside className={`aside-main ${openNav}`}>
					<Nav />
				</aside>
				<Container>
					<Row>{children}</Row>
					<div
						className={`overlay ${openNav}`}
						onClick={() => setNav(!openNav)}
						aria-hidden='true'
					/>
				</Container>
			</main>
		</>
	);
};

export default Layout;
