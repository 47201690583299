import React, { useContext } from 'react';

// Context
import { ApiContext } from '../../../context/ApiContext';

// Hooks
import useColumns from './Table/columns';

// Components
import Table from './Table';
import BetSlip from './BetSlip';

// Styles
import { TContainer } from './style';

const MakeData = ({ prematch }: any) => {
	const { games, prevGames } = useContext(ApiContext);
	const { columns } = useColumns();

	return (
		<TContainer>
			{!prematch ? <h1>Football - In-Play</h1> : <h1>Football - Pre Match</h1>}

			{(() => {
				if (!prematch) {
					if (games.length === 0) {
						return <p className='ng'>No games atm</p>;
					}
				} else if (prematch.length === 0) {
					return <p className='ng'>Games not available</p>;
				}
				return null;
			})()}

			{(() => {
				let gamesData = games;
				if (prematch) {
					gamesData = prematch;
				}

				const region = gamesData.map((el: any, elIndex: number) => {
					const country = el.alias;

					const competition = el.competition.map(
						(subEl: any, sIndex: number) => {
							const leagueName = subEl.name;
							const { game } = subEl;

							return (
								<React.Fragment key={sIndex}>
									<Table
										columns={columns}
										data={game}
										prevGames={prevGames}
										country={country}
										leagueName={leagueName}
										className={`table-${elIndex}-${sIndex}${
											prematch ? ' prematch' : ''
										}`}
									/>
								</React.Fragment>
							);
						}
					);

					return competition;
				});
				return region;
			})()}
			<BetSlip />
		</TContainer>
	);
};

export default MakeData;
