export const updateMarkets = (gameForUpdateCopy: any, newGame: any) => {
	let market = [...gameForUpdateCopy.market];
	const incommingMarket = newGame.market;

	if (market.length === 0) {
		market = incommingMarket;
	} else {
		const updatedMarkets = market.flatMap((mm: any) => {
			let marketForUpdateCopy = {
				...mm,
			};

			incommingMarket.map((im: any) => {
				if (mm.id === im.id) {
					// TODO ?
					// if (im.main_order && !im.market_type) {
					// 	// console.log('MAIN ORDER'); // ? do we need this?
					// }

					if (im.event) {
						const newMarket = mm.event.map((mmEvent: any) => {
							const eventForUpdateCopy = {
								...mmEvent,
							};

							im.event.map((imEvent: any) => {
								// TODO: New event incoming for POC+
								// if (im.event && im.market_type) {
								// 	console.log('NEW EVENT INCOMING');
								// 	// gameToUpdate.market = [
								// 	// 	...gameToUpdate.market,
								// 	// 	im,
								// 	// ];
								// }

								// Update events
								if (mmEvent.id === imEvent.id) {
									Object.keys(imEvent).forEach((k) => {
										if (k in mmEvent) {
											eventForUpdateCopy[k] = imEvent[k];
										}
									});
								}

								return null;
							});

							return eventForUpdateCopy;
						});

						marketForUpdateCopy.event = newMarket;
					}

					// Delete market
					if (im.deleted) {
						marketForUpdateCopy = null;
					}
				}

				return null;
			});

			return marketForUpdateCopy !== null ? marketForUpdateCopy : [];
		});

		market = updatedMarkets;

		// Add new incoming market
		incommingMarket.map((im: any) => {
			if (im.event && im.market_type) {
				market = [...market, im];
			}
			return null;
		});
	}

	return market;
};
