import React from 'react';
import { ToastContainer } from 'react-toastify';

// Context
import ApiContextProvider from './context/ApiContext';
import DropdownContextProvider from './context/DropdownContext';
import NavContextProvider from './context/NavContext';
import ContractsContextProvider from './context/ContractsContext';

// Routes
import Routes from './routes/Routes';

// General Styles
import GeneralStyles from './shared/styles/GeneralStyles';

function App() {
	return (
		<ApiContextProvider>
			<DropdownContextProvider>
				<NavContextProvider>
					<ContractsContextProvider>
						<ToastContainer position='bottom-right' theme='dark' />
						<GeneralStyles />
						<Routes />
					</ContractsContextProvider>
				</NavContextProvider>
			</DropdownContextProvider>
		</ApiContextProvider>
	);
}

export default App;
