import React, { useEffect, useMemo, useState } from 'react';
import useWebSocket from 'react-use-websocket';
import { getPrematchGames, wsAuth } from '../../../../config/api';

// Components
import Layout from '../../../general/Layout';
import MakeData from '../MakeData';

const Index = (props: any) => {
	const [games, setGames] = useState();
	const [loading, setLoading] = useState(true);

	// Get competitions ids
	const compIds = props.location.state.ids;

	// Format ids
	const formatIds = useMemo(() => {
		const ids = [] as any;
		compIds.map((el: any) => {
			ids.push(Object.values(el)[0]);
			return null;
		});
		return ids;
	}, [compIds]);

	const { sendJsonMessage, readyState } = useWebSocket(
		`${process.env.REACT_APP_WS_SERVER}`,
		{
			onMessage: (evt: MessageEvent<any>) => {
				const result = JSON.parse(evt.data);

				if (result?.rid === 3) {
					const gameData = result?.data?.data?.region;
					setGames(gameData);
					setLoading(false);
				}
			},
		},
		true
	);

	useEffect(() => {
		sendJsonMessage(wsAuth);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		sendJsonMessage(getPrematchGames(formatIds));
		return () => {
			setLoading(true);
		};
	}, [sendJsonMessage, readyState, formatIds, props.location.state]);

	// TODO remove hover on all cells

	return (
		<Layout>
			{games && loading === false ? (
				<MakeData prematch={games} />
			) : (
				<p>loading</p>
			)}
		</Layout>
	);
};

export default Index;
