// Get new market price when Bet Slip updates and detect blocked slip
export const getNewMarketPrice = (games: any, prevGames: any, slip: any) => {
	let newOddPrice = slip.odd;
	let blocked = 0;

	games.map((region: any, rindex: number) => {
		region.competition.map((comp: any, cindex: number) => {
			comp.game.map((game: any, gindex: number) => {
				if (game.id === slip.gameId) {
					if (game.is_blocked === 1) {
						blocked = 1;
						newOddPrice = '-';
					} else {
						const prevMarket =
							prevGames[rindex].competition[cindex].game[gindex].market;

						// If some market gets deleted but games is not blocked
						if (prevMarket.length > game.market.length) {
							prevMarket
								.filter(
									({ id: id1 }: any) =>
										!game.market.some(({ id: id2 }: any) => id2 === id1)
								)
								.map((mm: any) => {
									if (mm.id === slip.marketId) {
										mm.event.map((event: any) => {
											if (event.id === slip.eventId) {
												newOddPrice = '-';
											}
											return null;
										});
									}
									return null;
								});
						}

						// Normal game
						game.market.map((market: any) => {
							if (market.id === slip.marketId) {
								market.event.map((event: any) => {
									if (event.id === slip.eventId) {
										if (event.price !== slip.odd) {
											newOddPrice = event.price;
										}
									}
									return null;
								});
							}
							return null;
						});
					}
				}
				return null;
			});
			return null;
		});
		return null;
	});

	return [newOddPrice, blocked];
};
