import { useState, useEffect } from 'react';

export const useMobleDetect = () => {
	const [isTablet, setIsTablet] = useState(false);
	const [isMobile, setIsMobile] = useState(false);

	const validateMobile = () => {
		// Check window size
		if (window.innerWidth > 1200) {
			setIsTablet(false);
		} else {
			setIsTablet(true);
		}

		if (window.innerWidth <= 525) {
			setIsMobile(true);
		} else {
			setIsMobile(false);
		}
	};

	useEffect(() => {
		const resizeWindow = () => {
			validateMobile();
		};

		validateMobile();
		window.addEventListener('resize', resizeWindow);

		return () => {
			window.removeEventListener('resize', resizeWindow);
		};
	}, [isTablet, isMobile]);

	return {
		isTablet,
		isMobile,
	};
};
