// Auth
export const wsAuth = {
	command: 'request_session',
	params: {
		language: 'eng',
		site_id: '4',
		product: 2,
		tree_mode: 'list',
		source: '16',
	},
	rid: 0,
};

// Get games
export const getInPlay = {
	command: 'get',
	params: {
		source: 'betting',
		what: {
			game: [
				[
					'id',
					'start_ts',
					'is_blocked',
					'text_info',
					'match_length',
					'info',
					'stats',
					'team1_name',
					'team2_name',
				],
			],
			region: [],
			competition: ['name'], // get only name prop
			market: [],
			event: [],
		},
		where: {
			competition: {
				'@limit': 3,
			},
			game: {
				type: 1, // only live games (0:prematch 1:live 2:future games)
				'@node_limit': 1,
			},
			sport: {
				id: 1, // id for soccer - get only soccer games
			},
			market: {
				market_type: {
					'@in': ['MatchResult', 'HalfTimeResult', 'OverUnder'], // get only these markets
				},
			},
		},
		subscribe: true,
	},
	rid: 1,
};

// Get prematch regions
export const getUpcomingRegions = {
	command: 'get',
	params: {
		source: 'betting',
		what: {
			region: [],
			competition: ['id'], // get only name prop
		},
		where: {
			game: {
				type: 0, // only live games (0:prematch 1:live 2:future games)
				start_ts: {
					'@now': { '@gt': 0, '@lt': 99999 },
				},
			},
			sport: {
				id: 1, // id for soccer - get only soccer games
			},
		},
		subscribe: false,
	},
	rid: 2,
};

// Get prematch games
export const getPrematchGames = (id: any) => {
	const getGame = {
		command: 'get',
		params: {
			source: 'betting',
			what: {
				region: [],
				game: [
					[
						'id',
						'start_ts',
						'is_blocked',
						'text_info',
						'match_length',
						'info',
						'stats',
						'team1_name',
						'team2_name',
					],
				],
				competition: ['name'], // get only name prop
				market: [],
				event: [],
			},
			where: {
				game: {
					type: 0, // only live games (0:prematch 1:live 2:future games)
				},
				sport: {
					id: 1, // id for soccer - get only soccer games
				},
				competition: {
					id: { '@in': id },
				},
				market: {
					market_type: {
						'@in': ['MatchResult', 'HalfTimeResult', 'OverUnder'], // get only these markets
					},
				},
			},
			subscribe: false,
		},
		rid: 3,
	};
	return getGame;
};
