import React, { useState, createContext } from 'react';

// Interfaces
interface ContextProps {
	wallet: any;
	setWallet: any;
	network: any;
	setNetwork: any;
	provider: any;
	setProvider: any;
	signer: any;
	setSigner: any;
	stablecoinBalance: any;
	setStablecoinBalance: any;
	busdBalance: any;
	setBusdBalance: any;
	allowance: any;
	setAllowance: any;

	isConnected: any;
	setIsConnected: any;
	address: any;
	setAddress: any;
	balances: any;
	setBalances: any;
	betAllowance: number;
	setBetAllowance: any;
	networkName: string;
	setNetworkName: any;
}

interface ProviderProps {
	children: JSX.Element | JSX.Element[] | React.ReactNode;
}

export const ContractsContext = createContext<Partial<ContextProps>>({});

const ContractsContextProvider = ({ children }: ProviderProps) => {
	const [provider, setProvider] = useState<any>();
	const [wallet, setWallet] = useState<any>();
	const [isConnected, setIsConnected] = useState(false);
	const [address, setAddress] = useState("");
	const [network, setNetwork] = useState("");
	const [networkName, setNetworkName] = useState("");
	const [balances, setBalances] = useState({});
	const [signer, setSigner] = useState<any>();
	const [stablecoinBalance, setStablecoinBalance] = useState<any>('');
	const [busdBalance, setBusdBalance] = useState<any>('');
	const [allowance, setAllowance] = useState<any>('');

	// Bet allowance
	const [betAllowance, setBetAllowance] = useState(0);

	return (
		<ContractsContext.Provider
			value={{
				wallet,
				setWallet,
				network,
				setNetwork,
				provider,
				setProvider,
				signer,
				setSigner,
				stablecoinBalance,
				setStablecoinBalance,
				busdBalance,
				setBusdBalance,
				allowance,
				setAllowance,
				address,
				setAddress,
				isConnected,
				setIsConnected,
				balances,
				setBalances,
				betAllowance,
				setBetAllowance,
				networkName,
				setNetworkName,
			}}
		>
			{children}
		</ContractsContext.Provider>
);
};

export default ContractsContextProvider;
