import React from 'react';
import Moment from 'react-moment';
import moment from 'moment';

const Time = ({ time }: any) => {
	const { officialStart, realStart, prematch } = time;

	const currentTime = moment().unix();

	// Minutes
	const diff = currentTime - officialStart;
	const minDiff = Number(moment.unix(diff).format('mm'));
	const realMinDiff = Number(minDiff) - realStart;

	// Seconds
	const secDiff = moment.unix(diff).format('ss');

	// Combine m + s
	const start = moment()
		.add(-(minDiff - realMinDiff), 'm')
		.add(-secDiff, 's');

	// Get match  and current day
	const offDay = moment.unix(officialStart).format('MMM D');
	const currentDay = moment().format('MMM D');

	return (
		<>
			{prematch ? (
				<>
					{/* Check if there are later matches on the same day */}
					{offDay === currentDay && 'Starting at '}
					<Moment
						unix
						format={offDay !== currentDay ? 'MMMM Do YYYY, H:mm' : 'H:mm'}
					>
						{officialStart}
					</Moment>
				</>
			) : (
				<Moment date={start} format='mm:ss' durationFromNow interval={1000} />
			)}
		</>
	);
};

export default Time;
