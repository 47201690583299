import React, { useState } from 'react';

// Components
import Layout from '../../general/Layout';
import Buy from './Buy';

// Styles
import { Tabs } from './style';

const Index = () => {
	const [active, setActive] = useState('buy');

	return (
		<Layout>
			<Tabs>
				<button
					type='button'
					className={active === 'buy' ? 'active' : ''}
					onClick={() => setActive('buy')}
				>
					Buy our token
				</button>
				<button
					type='button'
					className={active === 'sell' ? 'active' : ''}
					onClick={() => setActive('sell')}
				>
					Sell our token
				</button>
			</Tabs>
			<Buy active={active} />
		</Layout>
	);
};

export default Index;
