export const updateInfo = (gameForUpdateCopy: any, newGame: any) => {
	const { info } = gameForUpdateCopy;
	const incommingInfo = newGame.info;

	Object.keys(incommingInfo).forEach((k) => {
		info[k] = incommingInfo[k];
	});

	return info;
};
