/* eslint-disable */
import { useState } from 'react';
import Web3Modal from 'web3modal';
import { BigNumber, ethers } from 'ethers';
import { singletonHook } from 'react-singleton-hook';
import ADDRESSES from '../../shared/contracts/addresses.json';
import { busdAbi, stablecoinAbi } from '../contracts/ABIs';
import bettingAbi from '../contracts/betting.abi.json';

const CHAINS = {
  1: "Ethereum Mainnet",
  3: "Ropsten",
  4: "Rinkeby",
  5: "Goerli",
  42: "Kovan",
  97: "BSC Testnet",
  137: "Polygon",
  80001: "Matic Mumbai"
};

class Web3Service {
  constructor(chainId = 1) {
    this.chainId = chainId;
    this.adminWalletKey = process.env.REACT_APP_WALLET_KEY
    this.faucetWalletKey = process.env.REACT_APP_FAUCET_WALLET_KEY
    this.adminWalletAddress = process.env.REACT_APP_WALLET_ADDRESS
    this.networkProvider = {};
    this.providerOptions = {
      options: {
        appName: "Betrust",
        networkUrl: `${process.env.REACT_APP_RPC_ENDPOINT}`,
        chainId: this.chainId,
      },
      connector: async (_, options) => {
        const { appName, networkUrl, chainId } = options;
        this.walletLink = new WalletLink({
          appName,
        });
        const provider = this.walletLink.makeWeb3Provider(
          networkUrl,
          chainId
        );
        await provider.enable();
        return provider;
      },
    };
    this.web3Modal = new Web3Modal({
      cacheProvider: true,
      providerOptions: this.providerOptions,
      theme: "dark",
    });
  }

  parseChainId = (id) => {
    const chains = {
      // 1: "Mainnet",
      // 3: "Ropsten",
      97: "BSC Testnet"
    };
    return chains[id] || "Wrong network";
  };

  parseNetworkName = (id) => {
    return CHAINS[id] || '-';
  }

  toBigNumber = (number) => {
    BigNumber.config({ ROUNDING_MODE: BigNumber.ROUND_FLOOR });
    return new BigNumber(number);
  };

  async connectToProvider() {
    this.networkProvider = await this.web3Modal.connect();
    if (!(await this.isValidNetwork())){
      try {
        await this.networkProvider.request({
          method: 'wallet_addEthereumChain',
          params: [
            {
              chainId: '0x61',
              chainName: 'Binance Smart Chain Testnet',
              nativeCurrency: {
                name: 'Binance Coin',
                symbol: 'BNB',
                decimals: 18,
              },
              rpcUrls: ['https://data-seed-prebsc-1-s1.binance.org:8545/'],
              blockExplorerUrls: ['https://testnet.bscscan.com/'],
            },
          ],
        });
      } catch (error) {
        console.log(error);
      }
    }
    location.reload();
  }

  async checkCachedProvider() {
    if (this.web3Modal.cachedProvider) {
      this.networkProvider = await this.web3Modal.connect();
    }
  }

  async getNetworkName() {
    await this.checkCachedProvider();
    if (Object.keys(this.networkProvider).length > 0) {
      const provider = new ethers.providers.Web3Provider(this.networkProvider);
      const network = await provider.getNetwork();
      return this.parseNetworkName(network.chainId);
    }
    return '-';
  }

  async onChainChanged() {
    return new Promise(async (resolve, reject) => {
      await this.checkCachedProvider();
      if (Object.keys(this.networkProvider).length > 0) {
        this.networkProvider.on("chainChanged", async (info) => {
          return resolve(this.parseChainId(parseInt(info, 16)));
        });
      }
    });
  }

  async onAccountChanged() {
    return new Promise(async (resolve, reject) => {
      await this.checkCachedProvider();
      if (Object.keys(this.networkProvider).length > 0) {
        this.networkProvider.on(
          "accountsChanged",
          async (accounts) => await resolve(accounts[0])
        );
      }
    })

  }

  async isProviderConnected() {
    await this.checkCachedProvider();
    if (Object.keys(this.networkProvider).length > 0) {
      const provider = new ethers.providers.Web3Provider(this.networkProvider);
      const accounts = await provider.listAccounts();
      return accounts.length > 0;
    }
    return false;
  }

  async isValidNetwork() {
    const network = await this.getNetwork();
    return network !== "Wrong network";
  }

  async getNetwork() {
    await this.checkCachedProvider();
    if (Object.keys(this.networkProvider).length > 0) {
      const provider = new ethers.providers.Web3Provider(this.networkProvider);
      const network = await provider.getNetwork();
      return this.parseChainId(network.chainId);
    }
    return false;
  }

  disconnectProvider() {
    this.web3Modal.clearCachedProvider();
    if (this.networkProvider._relay?.appName === "Betrust") {
      this.walletLink.disconnect();
    }
    location.reload();
  }

  async adminSignMessage(message) {
    await this.checkCachedProvider();
    if (Object.keys(this.networkProvider).length > 0) {
      const provider = new ethers.providers.Web3Provider(this.networkProvider);
      const signer = new ethers.Wallet(this.adminWalletKey, provider);
      return await signer.signMessage(message)
    }
    return "";
  }

  async getBNBFromFaucet (address) {
    const provider = new ethers.providers.Web3Provider(this.networkProvider);
    const signer = new ethers.Wallet(this.faucetWalletKey, provider);
    let balance = ethers.utils.formatEther(await signer.getBalance());
    const amount = process.env.REACT_APP_FAUCET_GET_AMOUNT || '0';

    if (parseFloat(balance) >= Number(amount)) {
      let tx = {
        to: address,
        value: ethers.utils.parseEther(amount),
      };
      await signer.signTransaction(tx);
      return await signer.sendTransaction(tx);
    }
    return null;
  };

  async addWinningOption(eventId, option) {
    await this.checkCachedProvider();
    if (Object.keys(this.networkProvider).length > 0) {
      const provider = new ethers.providers.Web3Provider(this.networkProvider);
      const signer = new ethers.Wallet(this.adminWalletKey, provider);
      const bettingContract = new ethers.Contract(
        ADDRESSES["Betting"],
        bettingAbi,
        signer
      );
      return await bettingContract.addWinningOptionToSet(BigNumber.from(eventId), BigNumber.from(option), { gasLimit: 300000, gasPrice: ethers.utils.parseEther('0.00000005')})
    }
  }

  parseForecast(data) {
    let forecast = "1";
    if (data.marketName.includes("Half-time Result")) forecast = "2";
    if (data.marketName.includes("Match Goals")) forecast = "3";
    if (data.eventType === "P1" || data.eventType === "Over") forecast = forecast + "1"
    if (data.eventType === "X" || data.eventType === "Under") forecast = forecast + "2"
    if (data.eventType === "P2") forecast = forecast + "3"
    if (data.base > 0) {
      let base = parseInt(data.base);
      forecast = forecast + base.toString();
    }

    return Number(forecast)
  }

  async placeBet(slipData, amount) {
    await this.checkCachedProvider();
    if (Object.keys(this.networkProvider).length > 0) {
      const provider = new ethers.providers.Web3Provider(this.networkProvider);
      const adminSigner = new ethers.Wallet(this.adminWalletKey, provider);
      const signer = await provider.getSigner();
      const bettingContract = new ethers.Contract(
        ADDRESSES.Betting,
        bettingAbi,
        signer
      );

      const bettingPairs = slipData.map(data => {
        return {
          eventId: BigNumber.from(data.gameId),
          betForecast: BigNumber.from(this.parseForecast(data)),
          odd: ethers.utils.parseEther(data.odd.toString()),
        }
      })

      amount = ethers.utils.parseEther(amount.toString())
      const message = "Hello world";
      const signature = await adminSigner.signMessage(message);
      const msgHash = ethers.utils.hashMessage(message);
      const digest = ethers.utils.arrayify(msgHash);

      bettingContract.on('NewBet', (slipId) => {
        localStorage.setItem('bettingSlipId', slipId);
      })

      return await bettingContract.placeBet(bettingPairs, amount, digest, signature, { gasLimit: 300000, gasPrice: ethers.utils.parseEther('0.00000005')});
    }
  }

  async claimWinnings (slipId) {
    await this.checkCachedProvider();
    if (Object.keys(this.networkProvider).length > 0) {
      const provider = new ethers.providers.Web3Provider(this.networkProvider);
      const signer = await provider.getSigner();
      const bettingContract = new ethers.Contract(
        ADDRESSES.Betting,
        bettingAbi,
        signer
      );

      bettingContract.on('RewardClaimed', (slipId, cashOutAmount) => {
        localStorage.removeItem('bettingSlipId');
      })

      return await bettingContract.claimRewards(slipId, { gasLimit: 300000, gasPrice: ethers.utils.parseEther('0.00000005')})
    }
  }

  async depositFunds() {
    await this.checkCachedProvider();
    if (Object.keys(this.networkProvider).length > 0) {
      const provider = new ethers.providers.Web3Provider(this.networkProvider);
      const signer = new ethers.Wallet(this.adminWalletKey, provider);
      const bettingContract = new ethers.Contract(
        ADDRESSES["Betting"],
        bettingAbi,
        signer
      );
      return await bettingContract.depositFunds(ethers.utils.parseEther('0.2'), { gasLimit: 300000, gasPrice: ethers.utils.parseEther('0.00000005')})
    }
  }

  async setCashOutMaximum() {
    await this.checkCachedProvider();
    if (Object.keys(this.networkProvider).length > 0) {
      const provider = new ethers.providers.Web3Provider(this.networkProvider);
      const signer = new ethers.Wallet(this.adminWalletKey, provider);
      const bettingContract = new ethers.Contract(
        ADDRESSES["Betting"],
        bettingAbi,
        signer
      );
      return await bettingContract.setCashOutMaximum(ethers.utils.parseEther('1'), { gasLimit: 300000, gasPrice: ethers.utils.parseEther('0.00000005')})
    }
  }

  async fetchAddress() {
    await this.checkCachedProvider();
    if (Object.keys(this.networkProvider).length > 0) {
      const provider = new ethers.providers.Web3Provider(this.networkProvider);
      const accounts = await provider.listAccounts();
      return accounts[0];
    }
    throw new Error("No network provider found!");
  }

  async fetchBalances(address) {
    await this.checkCachedProvider();
    const balances = {};
    if (Object.keys(this.networkProvider).length > 0) {
      const provider = new ethers.providers.Web3Provider(this.networkProvider);
      const signer = await provider.getSigner();
      const valid = await this.isValidNetwork();
      if (valid) {
        balances['bnb'] = ethers.utils.formatEther(await signer.getBalance());
        const stableCoinContract = new ethers.Contract(
          ADDRESSES["Stablecoin"],
          stablecoinAbi,
          signer
        );
        const busdContract = new ethers.Contract(
          ADDRESSES["BUSD"],
          busdAbi,
          signer
        );
        balances['mtk'] = await this.getBalance(stableCoinContract, address);
        balances['busd'] = await this.getBalance(busdContract, address);
      }
      for (const balance in balances) {
        balances[balance] = Number(balances[balance]).toFixed(3);
      }
      return balances;
    }
    throw new Error("No network provider found!");
  }

  async getBalance(contract, address) {
    return new Promise(async (resolve, reject) => {
      const balance = await contract.balanceOf(address);
      return resolve(ethers.utils.formatEther(balance));
    })
  }

  async approveBusd(amount) {
    await this.checkCachedProvider();
    if (Object.keys(this.networkProvider).length > 0) {
      const provider = new ethers.providers.Web3Provider(this.networkProvider);
      const signer = await provider.getSigner();
      const busdContract = new ethers.Contract(
        ADDRESSES.BUSD,
        busdAbi,
        signer
      );
      return busdContract.approve(
        ADDRESSES.Stablecoin,
        ethers.utils.parseEther(amount.toString())
      );
    }
  }

  async approveBettingOnMtk(amount) {
    if (Object.keys(this.networkProvider).length > 0) {
      const provider = new ethers.providers.Web3Provider(this.networkProvider);
      const signer = await provider.getSigner();
      const stableCoinContract = new ethers.Contract(
        ADDRESSES.Stablecoin,
        stablecoinAbi,
        signer
      )
      return stableCoinContract.approve(
        ADDRESSES.Betting,
        ethers.utils.parseEther(amount.toString())
      );
    }
  }

  async getAllowance(contract, address, contractAddress){
    return new Promise(async (resolve, reject) => {
      const allowance = await contract.allowance(address, contractAddress);
      return resolve(ethers.utils.formatEther(allowance))
    })
  };

  async getAllowanceBusd(address) {
    await this.checkCachedProvider();
    const valid = await this.isValidNetwork();
    if (Object.keys(this.networkProvider).length > 0 && address !== '' && valid) {
      const provider = new ethers.providers.Web3Provider(this.networkProvider);
      const signer = await provider.getSigner();
      const busdContract = new ethers.Contract(
        ADDRESSES.BUSD,
        busdAbi,
        signer
      );
      return await this.getAllowance(busdContract, address, ADDRESSES.Stablecoin);
    }
    return 0;
  }

  async getAllowanceBettingContract(address) {
    await this.checkCachedProvider();
    const valid = await this.isValidNetwork();
    if (Object.keys(this.networkProvider).length > 0 && address !== '' && valid) {
      const provider = new ethers.providers.Web3Provider(this.networkProvider);
      const signer = await provider.getSigner();
      const stableCoinContract = new ethers.Contract(
        ADDRESSES.Stablecoin,
        stablecoinAbi,
        signer
      );
      return await this.getAllowance(stableCoinContract, address, ADDRESSES.Betting);
    }
    return 0;
  }

  async buyMtkToken(amount) {
    await this.checkCachedProvider();
    if (Object.keys(this.networkProvider).length > 0 ) {
      const provider = new ethers.providers.Web3Provider(this.networkProvider);
      const signer = await provider.getSigner();
      let stablecoin = new ethers.Contract(
        ADDRESSES.Stablecoin,
        stablecoinAbi,
        signer
      );
      return await stablecoin.mintAndTransfer(
        ethers.utils.parseEther(amount.toString())
      );
    }
  }

  async sellMtkToken(amount) {
    await this.checkCachedProvider();
    if (Object.keys(this.networkProvider).length > 0 ) {
      const provider = new ethers.providers.Web3Provider(this.networkProvider);
      const signer = await provider.getSigner();
      let stablecoin = new ethers.Contract(
        ADDRESSES.Stablecoin,
        stablecoinAbi,
        signer
      );

      return await stablecoin.burnTokens(
        ethers.utils.parseEther(amount.toString())
      );
    }
  }

  async signNonce(nonce) {
    await this.checkCachedProvider();
    if (Object.keys(this.networkProvider).length > 0) {
      const provider = new ethers.providers.Web3Provider(this.networkProvider);
      const signer = provider.getSigner();
      return signer.signMessage(nonce);
    }
    throw new Error("No network provider found!");
  }
}


const init = new Web3Service();

const useWeb3ServiceImpl = () => {
  const [web3Service, setWeb3Service] = useState(new Web3Service());
  if(!web3Service) setWeb3Service(new Web3Service());
  return web3Service;
};

export const useWeb3Service = singletonHook(init, useWeb3ServiceImpl);


export default Web3Service;
