// Get market type
const getMarket = (market: any, marketName: string) =>
	market.filter((obj: any) => obj.type === marketName);

// Get Odds for Match results & Half Time results
export const getOdds = (marketData: any, marketName: string, index: number) => {
	let odd = '-' as number | string;
	let marketId;
	let eventId;

	// Get market type
	const market = getMarket(marketData, marketName);
	if (market[0]) {
		odd = parseFloat(market[0].event[index]?.price.toFixed(3));
		marketId = market[0].id;
		eventId = market[0].event[index]?.id; // TODO [index]? null handling
	}

	return [odd, marketId, eventId];
};

// Get Odds for Under / Over
export const getOverUnderOdds = (
	marketData: any,
	marketName: string,
	index?: number
) => {
	let odd = '-' as number | string;
	let base = '-' as number | string;
	let marketId;
	let eventId;

	// Get market type and find min value via order
	const market = getMarket(marketData, marketName);

	if (market.length > 0) {
		const item = market.reduce((prev: any, current: any) =>
			+prev.order < +current.order ? prev : current
		);

		marketId = item.id;

		if (index !== undefined) {
			if (item.event[index]?.price) {
				odd = parseFloat(item.event[index]?.price.toFixed(3));
			} else {
				odd = '-';
			}
			base = item.event[index]?.base;
			eventId = item.event[index]?.id;
		} else {
			odd = item.base;
			base = item.base;
			eventId = item.id;
		}
	}

	return [odd, base, marketId, eventId];
};
