import * as React from 'react';

function SvgWallet(props: React.SVGProps<SVGSVGElement>) {
	return (
		<svg
			width={17}
			height={16}
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
			{...props}
		>
			<path
				d='M15.445 3.672h-.211v-2.09C15.234.71 14.536 0 13.679 0H2.402C1.157 0 .132.968.012 2.204c-.017.087-.01-.69-.01 11.353 0 1.347 1.076 2.443 2.4 2.443h13.043c.857 0 1.555-.71 1.555-1.582V5.254c0-.872-.697-1.582-1.555-1.582zM2.402 1.087h11.277c.268 0 .487.222.487.495v2.09H2.402A1.345 1.345 0 011.07 2.38 1.345 1.345 0 012.4 1.087zm13.043 13.826H2.402a1.346 1.346 0 01-1.333-1.356v-9.21c.382.26.84.412 1.333.412h13.043c.269 0 .487.222.487.495v2.203h-4.018c-1.369 0-2.482 1.133-2.482 2.525 0 1.393 1.113 2.525 2.482 2.525h4.018v1.911a.492.492 0 01-.487.495zm.487-3.492h-4.018c-.78 0-1.414-.646-1.414-1.439s.634-1.439 1.414-1.439h4.018v2.878z'
				fill='#F9F9F9'
			/>
		</svg>
	);
}

export default SvgWallet;
