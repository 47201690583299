import * as React from 'react';

function SvgDropIcon(props: React.SVGProps<SVGSVGElement>) {
	return (
		<svg
			width={14}
			height={9}
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
			{...props}
		>
			<path d='M7 9l7-7L12.355.355 7 5.698 1.645.355 0 2l7 7z' fill='#F9F9F9' />
		</svg>
	);
}

export default SvgDropIcon;
