
import * as React from 'react';

function SvgTurnOff(props: React.SVGProps<SVGSVGElement>) {
	return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M3.78948 3.16799C4.16735 2.83268 4.74539 2.86714 5.0807 3.245C5.416 3.6228 5.38155 4.20091 5.00369 4.53621C3.94735 5.47371 3.3415 6.82065 3.3415 8.23175C3.3415 10.9551 5.5571 13.1707 8.2805 13.1707C11.0039 13.1707 13.2195 10.9551 13.2195 8.23175C13.2195 6.80669 12.6036 5.45091 11.5298 4.51195C11.1495 4.17945 11.1107 3.60164 11.4433 3.22134C11.7758 2.84104 12.3536 2.80238 12.7339 3.13488C14.2051 4.42121 15.0488 6.27895 15.0488 8.23175C15.0488 11.9638 12.0126 15 8.28056 15C4.5485 15 1.5123 11.9638 1.5123 8.23175C1.51224 6.29797 2.34223 4.45237 3.78948 3.16799Z" fill="white"/>
            <path d="M8.28068 6.70729C7.77556 6.70729 7.36605 6.29778 7.36605 5.79266V0.91463C7.36605 0.40951 7.77556 0 8.28068 0C8.7858 0 9.19531 0.40951 9.19531 0.91463V5.79266C9.19531 6.29784 8.7858 6.70729 8.28068 6.70729Z" fill="white"/>
        </svg>
    );
}

export default SvgTurnOff;
