import React from 'react';
import { useTable } from 'react-table';
import { useCreateSlip } from '../helpers/hooks/useCreateSlip';
import { useHighlightCell } from '../helpers/hooks/useHighlightCell';

// Styles
import { DHeader, TableWrap } from '../style';

// Interfaces
interface ITable {
	columns: any;
	data: any;
	prevGames: any;
	className?: string;
	country?: string;
	leagueName?: string;
}

const Index = ({
	columns,
	data,
	prevGames,
	className,
	country,
	leagueName,
}: ITable) => {
	const { highlight } = useHighlightCell();
	const { createSlip } = useCreateSlip();

	const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
		useTable({
			columns,
			data,
		});

	return (
		<>
			<div {...getTableProps()} className={`table ${className}`}>
				<div style={{ position: 'relative' }}>
					{headerGroups.map((headerGroup) => (
						<DHeader {...headerGroup.getHeaderGroupProps()}>
							{headerGroup.headers.map((column) => (
								<div className='row' {...column.getHeaderProps()}>
									<p>
										{column.render('Header', {
											country,
											leagueName,
										})}
									</p>
								</div>
							))}
						</DHeader>
					))}
				</div>
				<TableWrap {...getTableBodyProps()}>
					{rows.map((row) => {
						prepareRow(row);

						return (
							<div {...row.getRowProps()} className='row'>
								{row.cells.map((cell, index) => (
									<div className='cell' {...cell.getCellProps()}>
										{cell.render('Cell', {
											cellIndex: index,
											tableClass: className,
											prevGames,
											highlight,
											createSlip,
										})}
									</div>
								))}
							</div>
						);
					})}
				</TableWrap>
			</div>
		</>
	);
};

export default Index;
