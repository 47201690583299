import React, { useContext } from 'react';

// Context
import { BetSlipContext } from '../../../../context/BetSlipContext';

// Components
import TopSlip from './TopSlip';
import MiddleSlip from './MiddleSlip';
import BtnSlip from './BtnSlip';

// Styles
import { SlipWrap } from '../style';

const Index = () => {
	const { slipData, visible } = useContext(BetSlipContext);

	return (
		<>
			{slipData && slipData.length !== 0 && (
				<SlipWrap>
					<TopSlip />
					{!visible && (
						<>
							<MiddleSlip />
							<BtnSlip />
						</>
					)}
				</SlipWrap>
			)}
		</>
	);
};

export default Index;
