import styled from 'styled-components';
import { device, font } from '../../shared/styles/variables';

export const NWrap = styled.div`
	display: flex;
	height: 100%;
	flex-direction: column;
	justify-content: space-between;
	background-color: var(--sidebarBg);
`;

export const NContainer = styled.div`
	overflow-x: auto;
`;

export const NItems = styled.ul`
	flex: 1;
	display: flex;
	flex-direction: column;
	padding-top: 8px;
	/* overflow: hidden; */

	@media ${device.tabletL} {
		//
	}
`;

export const NSingleItem = styled.li`
	min-width: 256px;
	cursor: pointer;
	transition: all 0.25s ease-out;
	/* pointer-events: none; */
	overflow: hidden;

	.title {
		display: flex;
		align-items: center;
		justify-content: space-between;

		p {
			width: 100%;
			display: flex;
			justify-content: space-between;
		}

		svg {
			margin-right: 18px;
		}

		&.true {
			p {
				color: var(--white);
			}

			svg {
				transform: rotate(180deg);
			}
		}
	}

	a,
	p {
		padding: 11px 0 11px 20px;
		display: flex;
		align-items: center;
		font-size: 16px;
		color: var(--shadowBlue);
		transition: color 0.25s;
		pointer-events: auto;

		&.active {
			border-left: 3px solid var(--primary);
			padding-left: 17px;
		}

		&:hover {
			color: var(--white);
		}
	}

	a.active {
		color: var(--white);
	}
`;

export const NSubItem = styled.div`
	display: flex;
	flex-direction: column;

	&.hide {
		height: 0;
	}

	&.show {
		height: 73vh;
		overflow-y: scroll;
	}

	a {
		width: 100%;
		padding-left: 40px;

		&.active {
			padding-left: 40px;
			border: 0;
		}
	}
`;

// Header
export const Header = styled.div`
	width: 100%;
	/* padding: 12px 35px 12px 22px; */
	height: 64px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	background-color: var(--modalBg);
	position: fixed;
	z-index: 4;

	.logo {
		display: flex;
		height: 100%;
		width: 100%;
		max-width: 256px;
		background-color: var(--sidebarBg);
		padding: 17px 24px;

		img {
			width: 92px;
		}

		&:hover {
			opacity: 1;
		}
	}

	.m-wrap {
		display: flex;
		align-items: center;
		padding-left: 24px;

		.burger {
			padding: 5px 10px 5px 0;

			span {
				width: 24px;
				height: 4px;
				background-color: var(--text);
				border-radius: 10px;
				display: block;
				margin-bottom: 3px;

				&:last-of-type {
					margin-bottom: 0;
				}
			}
		}

		p {
			font-size: 14px;
			line-height: 20px;
			color: var(--text);
			${font.regular};
		}
	}

	@media ${device.tabletL} {
		/* padding: 11px 12px; */
	}
`;

export const RightWrap = styled.div`
	display: flex;
	align-items: center;
	flex: 1;
	justify-content: space-between;
	
	.networkButton {
		cursor: default;
    text-transform:capitalize;
	}

  .wrongNetworkButton {
    background: #F2545B;
		div {
      width: 100%;
      display: flex;
      justify-content: space-between;
			img {
				margin-right: 10px;
			}
		}
  }
`;

export const BalanceClaim = styled.div`
	display: flex;
	align-items: center;
	padding-left: 24px;

	p {
		margin-left: 16px;
		font-size: 14px;
	}
`;

export const HeaderBtns = styled.div`
	display: flex;
	align-items: center;
	position: relative;
	padding: 12px 24px;
	justify-content: space-around;
	svg {
		margin-right: 8px;
		margin-left: 8px;
	}
	button {
		margin-right: 8px;
	}
`;

export const ConnectBtn = styled.button`
	background-color: var(--text);
	color: var(--text);
	padding: 5px 15px;
	height: 31px;
	border-radius: 10px;
	transition: all 100ms ease-out;
	font-size: 14px;

	&:hover {
		opacity: 1;
		background-color: var(--text);
		box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.2);
	}
`;

export const DisconnectBtn = styled.button`
	background-color: var(--text);
	color: var(--white);
	padding: 5px 20px;
	border-radius: 10px;
	font-size: 14px;
	height: 31px;

	@media ${device.mobileM} {
		/* width: 96px;
		height: 30px; */
	}
`;
