import styled from 'styled-components';
import { font } from '../../styles/variables';

export const Wrap = styled.button`
	display: flex;
	align-items: center;
	align-self: center;
	justify-content: center;
	background-color: var(--primary);
	border-radius: 10px;
	${font.medium};
	font-size: 14px;
	line-height: 16px;
	color: var(--text);
	padding: 12px 32px;
	transition: all 0.25s;

	&:hover {
		background-color: var(--primaryHover);
		opacity: 1;
	}

	&:active {
		background-color: var(--primaryPush);
		color: var(--white);
	}

	&:disabled {
		opacity: 0.5;

		&:hover {
			background-color: var(--primary);
		}
	}
`;

export const WrapSecond = styled.button`
	display: flex;
	align-items: center;
	justify-content: center;
	align-self: center;
	background-color: var(--secondary);
	border-radius: 10px;
	${font.medium};
	font-size: 14px;
	line-height: 16px;
	color: var(--text);
	padding: 12px 32px;
	transition: all 0.25s;

	&:hover {
		background-color: var(--secondaryHover);
		opacity: 1;
	}

	&:active {
		background-color: var(--secondaryPush);
		color: var(--white);
	}

	&:disabled {
		opacity: 0.5;

		&:hover {
			background-color: var(--secondary);
		}
	}
`;
