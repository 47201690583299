import styled from 'styled-components';
import { font } from '../../../shared/styles/variables';

export const Tabs = styled.div`
	display: flex;
	width: 100%;
	margin-bottom: 25px;

	button {
		font-size: 16px;
		${font.medium}
		position:relative;

		&:first-of-type {
			margin-right: 32px;
		}

		&.active {
			&::before {
				content: '';
				position: absolute;
				left: 0;
				bottom: -5px;
				height: 2px;
				width: 100%;
				background-color: var(--primary);
			}
		}

		&:disabled {
			opacity: 0.5;
		}
	}
`;

export const Wrap = styled.div`
	display: block;
	width: 100%;
`;

export const Card = styled.div`
	display: block;
	background-color: var(--sidebarBg);
	margin-bottom: 24px;
	border-radius: 12px;

	.title {
		padding: 16px;
		border-bottom: 1px solid #7e96b84d;
		${font.medium}

		&:last-of-type {
			border: 0;
		}
	}
	
	.faucetButton {
		margin: 16px;
	}

	input {
		width: 356px;
		height: 38px;
		background-color: var(--background);
		box-shadow: none;
		outline: none;
		border: 0;
		border-radius: 12px;
		padding: 12px;
		margin-left: 16px;
		margin-bottom: 16px;
		color: var(--text);

		&::placeholder {
			font-size: 12px;
			color: var(--text);
			opacity: 0.6;
		}
	}
`;

export const CardItem = styled.div`
	display: flex;
	border-bottom: 1px solid #7e96b84d;

	&:last-of-type {
		border-bottom: 0;
	}

	p {
		padding: 16px;

		&:first-of-type {
			width: 310px;
			border-right: 1px solid #7e96b84d;
		}
	}

	a {
		padding: 16px;
	}

	button {
		margin-bottom: 16px;

		&:first-of-type {
			margin-right: 16px;
			margin-left: 16px;
		}
	}
`;
