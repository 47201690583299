import styled, { createGlobalStyle } from 'styled-components';

import { color, device, font } from './variables';

export const Container = styled.div`
	width: 100%;
	height: 100vh;
	padding: 0 14px;
	margin-top: 22px;
	flex: 1;
	margin-left: 256px;
	position: relative;
	overflow-x: scroll;

	.overlay {
		position: absolute;
		height: 100%;
		top: 0;
		z-index: 2;
		background-color: rgba(0, 0, 0, 0.2);
		opacity: 0;
		visibility: hidden;
		pointer-events: none;
		transition: opacity 0.25s;

		&.true {
			pointer-events: auto;
			opacity: 1;
			visibility: visible;
		}
	}

  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type=number] {
    -moz-appearance: textfield;
  }

	@media (min-width: 1200px) {
		padding: 0;
	}

	@media ${device.tabletL} {
		margin-left: 0;
		margin-top: 0;
		padding: 0 12px;
	}
`;

export const Row = styled.div`
	display: flex;
	flex-wrap: wrap;
	width: 100%;
	max-width: 1136px;
	margin: 0 auto;
	margin-top: 64px;

	@media (max-width: 1410px) {
		max-width: 1000px;
	}

	@media (max-width: 1290px) {
		max-width: 900px;
	}

	@media ${device.tabletL} {
		max-width: 100%;
		margin-top: 69px;
	}
`;

export default createGlobalStyle`
	body[data-theme='main'] {
		--text: ${color.text};
		--background: ${color.background};
		--white: ${color.white};
		--modalBg: ${color.modalBg};
		--sidebarBg: ${color.sidebarBg};
		--primary: ${color.primary};
		--primaryHover: ${color.primaryHover};
		--primaryPush: ${color.primaryPush};
		--secondary: ${color.secondary};
		--secondaryHover: ${color.secondaryHover};
		--secondaryPush: ${color.secondaryPush};
		--shadowBlue: ${color.shadowBlue};
		--activeGame: ${color.activeGame};
		--red: ${color.red};
		--blue: ${color.blue};
		--blue2: ${color.blue2};
		--gray: ${color.gray};
	}

	html, body, #root {
		height: 100%;
		min-height: 100%;
		margin: 0;
		overflow: hidden;

		&.ReactModal__Body--open {
			.ReactModalPortal > * {
				opacity: 1
			}
		}
	}

	body {
		color:  var(--text);
		-webkit-tap-highlight-color: transparent;
		font-size: 100%;
		${font.regular};
		background-color: var(--background);
	}

	#root {
		/* overflow-y:auto; */
		

		main {
			display: flex;
			flex: 1;
			padding-bottom:100px;

			@media ${device.tabletL} {
				padding-bottom:16px;
			}
		}
	}


	*, *:after, *:before, input[type="search"] {
		margin: 0;
		padding: 0;
		box-sizing: border-box;
	}

	td {
		color: var(--text);
	}

	a,button {
		display: inline-block;
		text-decoration: none;
		color: inherit;
		transition: opacity .25s;
		font-size: inherit;
		cursor: pointer;

		&:hover {
			opacity: .7;
		}

		&.disabled {
			pointer-events: none;
		}
	}

	ul {
		list-style: none;
	}

	ul, li, ol, dd, h1, h2, h3, h4, h5, h6, p {
		padding: 0;
		margin: 0;
	}

	button {
		background: none;
		border: none;
		${font.medium};
	}

	h1,
	h2,
	h3,
	h4,
	h5 {
		${font.medium};
	}

	h1 {
		font-size: 52px;
		line-height: 78px;
	}

	h2 {
		font-size: 42px;
		line-height: 41px;
	}

	h3 {
		font-size: 32px;
		line-height: 37px;
		margin-bottom: 24px;
	}

	h4 {
		font-size: 22px;
		line-height: 30px;
	}

	h5 {
		font-size: 18px;
		line-height: 24px;
	}

	p {
		font-size: 16px;
		line-height: 21px;
		${font.regular};
		color: var(--text);
	}

	a {
		display:block;
		font-size: 14px;
		line-height: 21px;
		${font.medium};
	}

	select,
	option {
		-webkit-appearance: none;
		-moz-appearance: none;
		appearance: none;
	}


	.aside-main {
		height: 100vh;
		max-width: 256px;
		padding-top: 64px;
		position: fixed;
		top: 0;
		left: 0;
		transition: left .25s ease-out;

		@media ${device.tabletL} {
			padding-top:53px;
			left: -100%;
			z-index:3;
			box-shadow: 0px 4px 8px rgb(0 0 0 / 4%);

			&.true {
				left:0;
			}

			.m-drop {
				display:block;
				position:relative;
				top:unset;
				right:unset;
				background-color:transparent;
				box-shadow:none;
				width: 100%;
			}
		}

		
	}

	.bn-onboard-custom {
		z-index: 1;
	}

	.a-wrapper {
		display: flex;
	}


	.nav-link {
		width: 100%;
		opacity: 1;
	}


	/* Workaround for IE11 focus highlighting for select elements */
	select::-ms-value {
		background: none;
		color: #42413d;
	}

	[role="button"], button, input, select, textarea {
		outline: none;
		&:focus {
			outline: none;
		}
		&:disabled {
			opacity: 1;
		}
	}

	[role="button"], button, textarea {
		appearance: none;
	}

	select:-moz-focusring {
		color: transparent;
		text-shadow: 0 0 0 #000;
	}

	select::-ms-expand {
		display: none;
	}

	


	.ReactModal__Overlay {
		cursor:pointer;
		
	}

	.ReactModalPortal {

		.ReactModal__Overlay {
			z-index: 9999;
			background-color: rgba(0, 0, 0, 0.4) !important;
		}

		.ReactModal__Content {
			box-shadow: 0px 3px 6px #0000000F;
			/* border: 1px solid #DADCE0 !important; */
			border: 0 !important;
			border-radius: 8px !important;
			cursor: initial;

			h1 {
				margin-bottom: 32px;
			}

			h2 {
				margin-bottom: 16px;
				font-size: 20px;
				line-height: 26px;
			}

			@media ${device.tabletL} {
				transform: translate(-50%, -50%) scale(0.87) !important;
			}

			@media (min-height: 1020px) {
				transform: translate(-50%, -50%) scale(1) !important;
			}

			@media ${device.mobileL} {
				padding:24px !important;
				transform: translate(-50%, -50%) scale(0.83) !important;

				h1 {
					font-size:22px;
				}
			}

			@media ${device.mobileM} {
				transform: translate(-50%, -50%) scale(0.75) !important;
			}

			@media ${device.mobileS} {
				transform: translate(-50%, -50%) scale(0.63) !important;
			}
		}

		& > * {
			opacity: 0;
		}

		@media ${device.mobileL} {
			.ReactModal__Content.m-modal {
				position: absolute;
				top: 50% !important;
				left: 50% !important;
				right: auto !important;
				bottom: auto !important;
				margin-right: -50% !important;
				transform: translate(-50%, -50%) !important;
				padding: 0 !important;
				background-color:white;
				box-sizing:border-box;
				height:100%;
				width:100%;
				overflow-y:scroll;
				border-radius: 0 !important;

				.top-close {
					top: 14px;
					right: 20px;
				}
			}
		}
	}

	.overlay {
		position: fixed;
		display: flex;
		justify-content: center;
		align-items: center;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		background-color: rgba(0,0,0, .6);
		z-index: 999;
		cursor: pointer;
		transition: opacity 200ms ease-in-out;

		&.ReactModal__Overlay--after-open {
				opacity: 1;
		}

		&.ReactModal__Overlay--before-close {
				opacity: 0;
		}
	}

	body, select {
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
	}

	html {
		touch-action: manipulation;
	}

	select {
		cursor: pointer;
	}
`;

interface ICol {
	width?: string;
}

export const Col = styled.div<ICol>`
	flex: 0 0 ${(props) => (props.width ? props.width : '100%')};
	width: ${(props) => (props.width ? props.width : '100%')};
	max-width: 100%;
	position: relative;
	padding-right: 14px;
	padding-left: 14px;

	h2 {
		font-size: 22px;
	}

	@media ${device.tabletL} {
		padding: 0;
	}
`;

export const Flex = styled.div`
	display: flex;
	flex-direction: column;
	flex-wrap: wrap;
	height: 100%;

	@media ${device.mobileL} {
		height: auto;

		&.d-wrap {
			padding: 75px 20px 32px 20px;
			box-sizing: border-box;
		}
	}

	@media ${device.mobileM} {
		&.d-wrap {
			padding: 75px 0 0 0;
		}
	}
`;
