/* eslint dot-notation: 0 */

import React, { useContext } from 'react';

// Context
import { ApiContext } from '../../../../context/ApiContext';
import BetSlipContextProvider from '../../../../context/BetSlipContext';

// Components
import Layout from '../../../general/Layout';
import MakeData from '../MakeData';

const Index = () => {
	const { games, prevGames } = useContext(ApiContext);

	return (
		<BetSlipContextProvider>
			<Layout>{games && prevGames ? <MakeData /> : <p>loading</p>}</Layout>
		</BetSlipContextProvider>
	);
};

export default Index;
