import React, { useRef } from 'react';
import moment from 'moment';

// Helpers
import { getOdds, getOverUnderOdds } from '../helpers/getOdds';

// Components
import Time from '../Time';
import GoalToast from '../GoalToast';

const useColumns = () => {
	const slipBtn = React.useMemo(() => {
		const btn = (
			odd: string | number, // fn return odd
			row: any, // game data
			ticketTitle: string,
			marketName: string, // Market name
			className: string, // btn class
			disabled?: boolean, // disable O/U cells
			// for betslip components
			marketId?: number, // Market type
			eventId?: number, // Event index inside market []
			is_blocked?: number,
			highlight?: any,
			createSlip?: any,
			base?: number,
			eventType?: string
		) => (
			<button
				type='button'
				className={[
					highlight && highlight.indexOf(className) >= 0
						? `${className} active`
						: className,
					(disabled || odd === '-') && 'inactive',
				].join(' ')}
				onClick={() => {
					if (!disabled && odd !== '-') {
						createSlip(
							row,
							odd,
							ticketTitle,
							marketName,
							className,
							row.id,
							marketId,
							eventId,
							is_blocked,
							base,
							eventType
						);
					}
				}}
			>
				{odd}
			</button>
		);
		return btn;
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return {
		columns: React.useMemo(
			() => [
				{
					Header: '',
					id: 'Football',
					columns: [
						{
							id: 'Leagues',
							Header: (data: any) => {
								const { country, leagueName } = data;
								return `${country}  ${leagueName}`;
							},
							Cell: ({ row, prevGames: tPrevGames }: any) => {
								const {
									team1_name,
									team2_name,
									start_ts,
									info,
									is_blocked,
									stats,
									text_info,
									id,
								} = row.original;

								// Setting scores for teams in ref to persist after rerender
								const scoreTeam1 = useRef(false);
								const scoreTeam2 = useRef(false);

								// Check for new goal and update ref
								tPrevGames.map((prevRegion: any) => {
									prevRegion.competition.map((prevComp: any) => {
										prevComp.game.map((prevGame: any) => {
											if (prevGame.id === id) {
												const gameInfo = text_info?.split(',').shift();
												const prevGameInfo = prevGame.text_info
													?.split(',')
													.shift();

												if (
													gameInfo.length > 0 &&
													prevGameInfo.length > 0 &&
													gameInfo.substring(0, 1) !==
														prevGameInfo.substring(0, 1)
												) {
													// @ts-ignore
													scoreTeam1.current = true;
												}

												if (
													gameInfo.length > 0 &&
													prevGameInfo.length > 0 &&
													gameInfo.substring(4, 5) !==
														prevGameInfo.substring(4, 5)
												) {
													// @ts-ignore
													scoreTeam2.current = true;
												}
											}

											return null;
										});

										return null;
									});

									return null;
								});

								return (
									<>
										<div>
											<div
												style={{
													flexDirection: 'row',
													justifyContent: 'flex-start',
													alignItems: 'flex-start',
												}}
											>
												<p>{team1_name}</p>
												{scoreTeam1.current && (
													<GoalToast scoreRef={scoreTeam1} />
												)}
											</div>
											<div
												style={{
													flexDirection: 'row',
													justifyContent: 'flex-start',
													alignItems: 'flex-start',
												}}
											>
												<p>{team2_name}</p>
												{scoreTeam2.current && (
													<GoalToast scoreRef={scoreTeam2} />
												)}
											</div>
											<p className='live'>
												<span>
													{/* {info.current_game_time
                      ? info.current_game_time
                      : info.current_game_state}
                    <br /> */}
													{(() => {
														// If time is available: show time / if game is blocked/not started: block time
														// If there is status insted of game time: show status('Half Time)
														let time;
														const officialStart = start_ts;
														const currentTime = moment().unix();

														if (info.current_game_time) {
															if (
																is_blocked === 0 &&
																info.current_game_state !== 'notstarted'
															) {
																time = (
																	<Time
																		time={{
																			officialStart,
																			realStart: info.current_game_time,
																		}}
																	/>
																);
															} else if (info.match_add_minutes) {
																time =
																	Number(info.current_game_time) +
																	Number(info.match_add_minutes);
															} else {
																time = info.current_game_time;
															}

															// If game is in prematch
															if (officialStart > currentTime) {
																time = (
																	<Time
																		time={{
																			officialStart,
																			realStart: info.current_game_time,
																			prematch: true,
																		}}
																	/>
																);
															}
														} else if (info.match_add_minutes) {
															time =
																Number(info.current_game_time) +
																Number(info.match_add_minutes);
														} else {
															time = info.current_game_state;
														}

														return time;
													})()}
												</span>
											</p>
										</div>
										<div className='score'>
											{stats.score_set1 && (
												<div>
													<p>{stats.score_set1.team1_value}</p>
													<p>{stats.score_set1.team2_value}</p>
													<p>HT</p>
												</div>
											)}
											<div>
												<p>{info.score1}</p>
												<p>{info.score2}</p>
												<p>FT</p>
											</div>
										</div>
									</>
								);
							},
						},
					],
				},
				{
					Header: 'Final Outcome',
					columns: [
						{
							Header: '1',
							Cell: ({
								row,
								cellIndex,
								tableClass,
								highlight,
								createSlip,
							}: any) => {
								const { team1_name, is_blocked, market } = row.original;
								const [odd, marketId, eventId] = getOdds(market, 'P1XP2', 0);

								return slipBtn(
									odd,
									row.original,
									team1_name,
									'Final Outcome',
									`btn-${tableClass}-${row.index}-${cellIndex}  ${
										is_blocked ? 'disabled' : 'enabled'
									}`,
									false,
									marketId,
									eventId,
									is_blocked,
									highlight,
									createSlip,
									0,
									'P1'
								);
							},
						},
						{
							Header: 'X',
							Cell: ({
								row,
								cellIndex,
								tableClass,
								highlight,
								createSlip,
							}: any) => {
								const { is_blocked, market } = row.original;
								const [odd, marketId, eventId] = getOdds(market, 'P1XP2', 1);

								return slipBtn(
									odd,
									row.original,
									'Draw',
									'Final Outcome',
									`btn-${tableClass}-${row.index}-${cellIndex}  ${
										is_blocked ? 'disabled' : 'enabled'
									}`,
									false,
									marketId,
									eventId,
									is_blocked,
									highlight,
									createSlip,
									0,
									'X'
								);
							},
						},
						{
							Header: '2',
							Cell: ({
								row,
								cellIndex,
								tableClass,
								highlight,
								createSlip,
							}: any) => {
								const { team2_name, is_blocked, market } = row.original;
								const [odd, marketId, eventId] = getOdds(market, 'P1XP2', 2);

								return slipBtn(
									odd,
									row.original,
									team2_name,
									'Final Outcome',
									`btn-${tableClass}-${row.index}-${cellIndex}  ${
										is_blocked ? 'disabled' : 'enabled'
									}`,
									false,
									marketId,
									eventId,
									is_blocked,
									highlight,
									createSlip,
									0,
									'P2'
								);
							},
						},
					],
				},
				{
					Header: 'Half-Time',
					columns: [
						{
							Header: '1',
							id: 'half1',
							Cell: ({
								row,
								cellIndex,
								tableClass,
								highlight,
								createSlip,
							}: any) => {
								const { team1_name, is_blocked, market } = row.original;
								const [odd, marketId, eventId] = getOdds(
									market,
									'HalfTimeResult',
									0
								);

								return slipBtn(
									odd,
									row.original,
									team1_name,
									'Half-time Result',
									`btn-${tableClass}-${row.index}-${cellIndex}  ${
										is_blocked ? 'disabled' : 'enabled'
									}`,
									false,
									marketId,
									eventId,
									is_blocked,
									highlight,
									createSlip,
									0,
									'P1'
								);
							},
						},
						{
							Header: 'X',
							id: 'halfx',
							Cell: ({
								row,
								cellIndex,
								tableClass,
								highlight,
								createSlip,
							}: any) => {
								const { is_blocked, market } = row.original;
								const [odd, marketId, eventId] = getOdds(
									market,
									'HalfTimeResult',
									1
								);

								return slipBtn(
									odd,
									row.original,
									'Draw',
									'Half-time Result',
									`btn-${tableClass}-${row.index}-${cellIndex}  ${
										is_blocked ? 'disabled' : 'enabled'
									}`,
									false,
									marketId,
									eventId,
									is_blocked,
									highlight,
									createSlip,
									0,
									'X'
								);
							},
						},
						{
							Header: '2',
							id: 'half2',
							Cell: ({
								row,
								cellIndex,
								tableClass,
								highlight,
								createSlip,
							}: any) => {
								const { team2_name, is_blocked, market } = row.original;
								const [odd, marketId, eventId] = getOdds(
									market,
									'HalfTimeResult',
									2
								);

								return slipBtn(
									odd,
									row.original,
									team2_name,
									'Half-time Result',
									`btn-${tableClass}-${row.index}-${cellIndex}  ${
										is_blocked ? 'disabled' : 'enabled'
									}`,
									false,
									marketId,
									eventId,
									is_blocked,
									highlight,
									createSlip,
									0,
									'P2'
								);
							},
						},
					],
				},

				{
					Header: 'Match Goals',
					columns: [
						{
							Header: 'Over',
							Cell: ({
								row,
								cellIndex,
								tableClass,
								highlight,
								createSlip,
							}: any) => {
								const { is_blocked, market } = row.original;
								const [odd, base, marketId, eventId] = getOverUnderOdds(
									market,
									'OverUnder',
									0
								);

								return slipBtn(
									odd,
									row.original,
									'Over',
									`Match Goals (O/U ${base})`,
									`btn-${tableClass}-${row.index}-${cellIndex}  ${
										is_blocked ? 'disabled' : 'enabled'
									}`,
									false,
									marketId,
									eventId,
									is_blocked,
									highlight,
									createSlip,
									base,
									'Over'
								);
							},
						},
						{
							Header: 'O/U',
							Cell: ({ row, cellIndex, tableClass }: any) => {
								const { is_blocked } = row.original;
								const [base] = getOverUnderOdds(
									row.original.market,
									'OverUnder'
								);

								return slipBtn(
									base,
									row.original,
									'O/U',
									`Match Goals (O/U ${base})`,
									`btn-${tableClass}-${row.index}-${cellIndex}  ${
										is_blocked ? 'disabled' : 'enabled'
									}`,
									true
								);
							},
						},
						{
							Header: 'Under',
							Cell: ({
								row,
								cellIndex,
								tableClass,
								highlight,
								createSlip,
							}: any) => {
								const { is_blocked, market } = row.original;
								const [odd, base, marketId, eventId] = getOverUnderOdds(
									market,
									'OverUnder',
									1
								);

								return slipBtn(
									odd,
									row.original,
									'Under',
									`Match Goals (O/U ${base})`,
									`btn-${tableClass}-${row.index}-${cellIndex}  ${
										is_blocked ? 'disabled' : 'enabled'
									}`,
									false,
									marketId,
									eventId,
									is_blocked,
									highlight,
									createSlip,
									base,
									'Under'
								);
							},
						},
					],
				},
			],
			// eslint-disable-next-line react-hooks/exhaustive-deps
			[slipBtn]
		),
	};
};
export default useColumns;
