import React from 'react';
import { NavLink } from 'react-router-dom';

// Styles
import { Wrap } from './style';

export interface ILink {
	children: JSX.Element | JSX.Element[] | React.ReactNode;
	to: string;
	external?: boolean;
	style?: React.CSSProperties;
	btn?: boolean;
	className?: string;
	state?: any;
}

const Link = ({
	children,
	to,
	external,
	style,
	btn,
	className,
	state,
}: ILink) =>
	!external ? (
		<NavLink
			exact
			to={{ pathname: to, state }}
			style={style}
			className={className}
		>
			{children}
		</NavLink>
	) : (
		<>
			{btn ? (
				<Wrap
					href={to}
					target='_blank'
					rel='noopener noreferrer'
					style={style}
					className={className}
				>
					{children}
				</Wrap>
			) : (
				<a
					href={to}
					target='_blank'
					rel='noopener noreferrer'
					style={style}
					className={className}
				>
					{children}
				</a>
			)}
		</>
	);

export default Link;
